@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
/*------------------------------------------------------------------


  font-family: 'Kanit', sans-serif;

    font-family: 'Lobster', cursive;

font-family: 'Karla', sans-serif;

-------------------------------------------------------------------*/

/*----------------------------------------*/
/* Template default CSS
/*----------------------------------------*/

html,
body {
  /* height: 100%; */
  overflow-x: hidden;
  max-width: 100%;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  background-color: #fdfdfd;
  scroll-behavior: smooth;
}

button {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #fff;
  font-weight: 600;
  font-family: "Inter", sans-serif;
}

h1 {
  font-size: 30px;
  color: #424242;
}

h2 {
  font-size: 18px;
  color: #861084;
}

h3 {
  font-size: 30px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 22px;
  color: #464764;
}

p {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  color: #555555;
  font-weight: 300;
  line-height: 26px;
  margin: 0 0 15px 0;
}

img {
  max-width: 100%;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
  outline: none;
}

a:hover,
a:focus {
  text-decoration: none !important;
  outline: none;
  transition: 0.1s;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

/*---------------------
  Helper CSS
-----------------------*/
table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {}

.section-title {
  margin-bottom: 40px;
  text-align: center;
}

.section-title .product-price {
  font-size: 24px;
  font-weight: 700;
  color: #e7ab3c;
}

.section-title .product-price span {
  font-size: 16px;
  font-weight: 400;
  color: #636363;
}

.section-title h2 {
  color: #252525;
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 38px;
  position: relative;
}

.section-title h2:before {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -14px;
  width: 80px;
  height: 3px;
  background: #e7ab3c;
  content: "";
  margin: 0 auto;
}

.section-title p {
  color: #6e6e6e;
}

.set-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

.spad {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pb-1 {
  padding-bottom: 10px;
}

.pb-2 {
  padding-bottom: 20px;
}

.pb-3 {
  padding-bottom: 30px;
}

.pb-4 {
  padding-bottom: 40px;
}

.pb-5 {
  padding-bottom: 50px;
}

.pt-1 {
  padding-top: 10px;
}

.pt-2 {
  padding-top: 20px;
}

.pt-3 {
  padding-top: 30px;
}

.pt-4 {
  padding-top: 40px;
}

.pt-5 {
  padding-top: 50px;
}

.pt-10 {
  padding-top: 100px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.mb-4 {
  margin-bottom: 40px;
}

.mb-5 {
  margin-bottom: 50px;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
  color: #fff;
}

/* buttons */

.primary-btn {
  display: inline-block;
  font-size: 17px;
  font-weight: 7400;
  padding: 10px 30px;
  color: #ffffff;
  border: 1px solid #941b92;
  color: #941b92;
  border-radius: 8px;
  font-family: "Inter", sans-serif;
}

.primary-btn:hover {
  background-color: #580b57;
  color: #fff;
}

.site-btn {
  color: #ffffff;
  background: #e7ab3c;
  border: 1px solid #e7ab3c;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 13px 45px 11px;
  cursor: pointer;
}

/* Preloder */

#preloder {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  background: #fdfdfd;
}

.loader {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -13px;
  border-radius: 60px;
  animation: loader 0.8s linear infinite;
  -webkit-animation: loader 0.8s linear infinite;
}

.text-right {
  text-align: right;
}

@keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }

  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}

@-webkit-keyframes loader {
  0% {
    -webkit-transform: rotate(0deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }

  50% {
    -webkit-transform: rotate(180deg);
    border: 4px solid #673ab7;
    border-left-color: transparent;
  }

  100% {
    -webkit-transform: rotate(360deg);
    border: 4px solid #f44336;
    border-left-color: transparent;
  }
}

/*---------------------
  Header
-----------------------*/

.header {
  padding: 25px 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.col-md-30 {
  display: inline-block;
  width: 29%;
  vertical-align: top;
}

.col-md-50 {
  display: inline-block;
  width: 49%;
  vertical-align: top;
}

.col-md-70 {
  display: inline-block;
  width: 69%;
  vertical-align: top;
}

.text-center {
  text-align: center;
}

.inner .s-box {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  width: 80%;
  border-radius: 13px;
  padding: 25px 20px;
  margin: 0 auto;
  z-index: 9999 !important;
}

.s-box h1 {
  font-size: 22px;
  z-index: 9999 !important;
}

.s-box .inp {
  position: relative;
  z-index: 9999 !important;
}

.inner .s-box p {
  padding-bottom: 15px;
  z-index: 9999 !important;
}

.india {
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 17px;
  padding: 8px 7px;
  border-right: 1px solid #ccc;
}

.s-box .inp input.mob[type="text"] {
  border: 1px solid #c0c0c0;
  padding: 8px 38px 8px 50px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 20px;
  font-size: 14px;
  z-index: 9999 !important;
}

.s-box .inp input.mob[type="text"]:focus {
  border: 1px solid #000;
  z-index: 9999 !important;
}

.s-box .inp input[type="text"] {
  border: 1px solid #c0c0c0;
  padding: 8px 8px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  /* margin-bottom: 20px; */
  font-size: 14px;
  z-index: 9999 !important;
}

.s-box .inp input[type="password"]:focus {
  border: 1px solid #000;
  z-index: 9999 !important;
}

.s-box .inp input[type="password"] {
  border: 1px solid #c0c0c0;
  padding: 8px 8px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  /* margin-bottom: 20px; */
  font-size: 14px;
  z-index: 9999 !important;
}

.s-box .inp input[type="text"]:focus {
  border: 1px solid #000;
  z-index: 9999 !important;
}

.s-box .inp input[type="email"] {
  border: 1px solid #c0c0c0;
  padding: 8px 8px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  /* margin-bottom: 20px; */
  font-size: 14px;
  z-index: 9999 !important;
}

.s-box .inp input[type="email"]:focus {
  border: 1px solid #000;
  z-index: 9999 !important;
}

.s-box .inp button {
  background-color: #04b646;
  padding: 15px;
  text-align: center;
  width: 100%;
  border-radius: 7px;
  color: #fff;
  display: block;
  text-decoration: none;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  z-index: 9999 !important;
}

.inp button:hover {
  background-color: #0466b0;
  z-index: 9999 !important;
}

.inp a {
  background-color: #04b646;
  padding: 15px;
  text-align: center;
  width: 100%;
  border-radius: 7px;
  color: #fff;
  display: block;
  text-decoration: none;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  font-size: 16px;
}

.inp a:hover {
  background-color: #018231;
}

.or-devide {
  position: relative;
}

.or-devide hr {
  position: relative;
  border: none;
  height: 1px;
  background: #c0c0c0;
  margin: 30px 0;
}

.or-devide span {
  position: absolute;
  left: 47%;
  top: -17px;
  background-color: #fff;
  padding: 5px;
}

.continue-with {
  text-align: center;
}

.continue-with .sign-b {
  padding: 8px 39px;
  border: 1px solid #018231;
  display: inline-block;
  border-radius: 40px;
  text-decoration: none;
  color: #000;
  font-size: 18px;
}

.continue-with .sign-b p {
  padding-bottom: 0;
}

.continue-with .sign-b:hover {
  border: 1px solid #018231;
  background-color: #018231;
  color: #fff;
}

.google button {
  display: block;
  text-decoration: none;
  padding: 8px 10px;
  text-align: center;
  vertical-align: middle;
  border: 1px solid #c4c3c3;
  margin-top: 20px;
  border-radius: 7px;
  color: #505050;
  font-weight: 300;
  width: 100%;
  font-size: 16px;
  /* box-shadow: inherit !important;
	border: 1px solid #ccc !important; */
}

.logout {
  background-image: url(../img/logout.png);
  background-position: left center;
  background-repeat: no-repeat;
  display: inline-block;
}

.logout a {
  padding: 7px 0 7px 30px;
  display: inline-block;
  text-decoration: none;
  color: #696969;
}

.logout a:hover {
  color: #0066b3;
}

.btm-brdr {
  border-bottom: 1px solid #ccc;
}

.inuput-box {
  position: relative;
  width: 100%;
}

.inuput-box input[type="text"] {
  border: 0px;
  border: 1px solid #c0c0c0;
  padding: 8px 8px;
  width: 100%;
  box-sizing: border-box;
  /* margin-bottom: 20px; */
  font-size: 14px;
}

.inuput-box input[type="text"]:focus {
  border: 1px solid #055eec;
}

.inuput-box input[type="number"] {
  border: 0px;
  border: 1px solid #c0c0c0;
  padding: 8px 8px;
  width: 100%;
  box-sizing: border-box;
  /* margin-bottom: 20px; */
  font-size: 14px;
}

.inuput-box input[type="number"]:focus {
  border: 1px solid #055eec;
}

.inuput-box input[type="date"] {
  border: 1px solid #c0c0c0;
  padding: 8px 8px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 20px;
  color: #767676;
}

.inuput-box input[type="date"]:focus {
  border: 1px solid #0066b3;
}

.inuput-box input[type="password"]:focus {
  border: 1px solid #0066b3;
}

.inuput-box input[type="password"] {
  border: 1px solid #c0c0c0;
  padding: 8px 8px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 20px;
}

.radio-box input[type="radio"] {
  /* margin-left: 5px; */
  margin-left: 12px;
}

.radio-box input[type="checkbox"] {
  margin-left: 15px;
}

.inuput-box input[type="email"] {
  border: 1px solid #c0c0c0;
  padding: 8px 8px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 20px;
}

.inuput-box input[type="email"]:focus {
  border: 1px solid #0066b3;
}

/* replace with a class name */
/* .inuput-box  button{
	background-color: #0066b3;
	padding: 15px;
	text-align: center;
	width: 100%;
	border-radius: 7px;
	color: #fff;
	display: block;
	text-decoration: none;
	box-sizing: border-box;
	border:0;
	cursor: pointer;
	font-size: 18px;
}  .inuput-box  button:hover{
	background-color: #045491;
} */
.inuput-box select {
  padding: 8px 8px;
  font-size: 14px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0px;
  border: 1px solid #c0c0c0;
  background-image: url(../img/arro-down.png);
  background-position: right 13px center;
  background-repeat: no-repeat;
}

.details h4 {
  color: #464764;
  font-size: 26px;
  font-weight: 600;
  padding-bottom: 5px;
}

.details h5 {
  color: #464764;
  font-size: 22px;
  font-weight: 600;
  padding-bottom: 5px;
}

.verification {
  margin-bottom: 10px;
}

.verification a {
  display: block;
  background-image: url(../img/innactive.png);
  background-position: 25px center;
  background-repeat: no-repeat;
  padding: 8px 30px 8px 67px;
  border-radius: 7px;
  color: #989797;
}

.inp-back button {
  background: none;
  border: none;
}

.inp-back button:hover {
  color: blue;
}

.verification a.active {
  display: block;
  background-image: url(../img/active.png);
  background-position: 25px center;
  background-repeat: no-repeat;
  padding: 8px 30px 8px 67px;
  border-radius: 7px;
  color: black;
}

.verification a.active:hover {
  color: blue;
}

.verification a.not-clickable {
  display: block;
  background-image: url(../img/active.png);
  background-position: 25px center;
  background-repeat: no-repeat;
  padding: 8px 30px 8px 67px;
  border-radius: 7px;
  color: #d5d7d9;
  cursor: default;
}

.verification a.previous {
  display: block;
  background-image: url(../img/active.png);
  background-position: 25px center;
  background-repeat: no-repeat;
  padding: 8px 30px 8px 67px;
  border-radius: 7px;
  color: black;
}

.verification a.process {
  display: block;
  background-image: url(../img/black.png);
  background-position: 25px center;
  background-repeat: no-repeat;
  padding: 8px 30px 8px 67px;
  border-radius: 7px;
  color: #000;
}

.verification a.process:hover {
  color: blue;
}

.card-detail {
  background-color: #fae3ad;
  border-radius: 7px;
  border: 1px solid #edce85;
  text-align: center;
  padding: 30px 0;
}

.gender img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #fff;
  margin-bottom: 10px;
}

.inp {
  display: block;
}

.inp button {
  background-color: #04b646;
  padding: 12px 55px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  text-decoration: none;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
}

.inp button:hover {
  background-color: #018231;
  /*#018231*/
}

a.normal-link {
  color: #0861d2;
  display: inline-block;
}

span.normal-link {
  color: #0861d2;
  display: inline-block;
  cursor: pointer;
}

.details {}

.radio-box {
  padding: 7px 14px;
  border: 1px solid #c4c3c3;
  font-size: 14px;
  background-color: #fff;
}

.radio-box label {
  margin-bottom: 0;
}

.edit-btn {
  position: absolute;
  right: 8px;
  top: 5px;
  font-size: 18px;
}

.edit-btn a {
  color: #000;
}

.time-otp {
  position: absolute;
  right: 5px;
  bottom: 5px;
  font-size: 12px;
}

.info {
  position: absolute;
  right: 40px;
  top: 0;
  background-color: #0066b3;
  border-radius: 50%;
  width: 25px;
  height: 28px;
  padding: 15px;
  line-height: 0;
  color: #fff !important;
}

.side-section {
  background-color: #fff;
}

.inner-section {
  background-color: #f4f6f8;
  height: auto;
}

.pt-55 {
  padding-top: 30px;
}

.line-1 {
  padding: 5px 0;
}

.head-section {
  background-color: #f4f4f4;
  border-bottom: 1px solid #dbdbdb;
}

/*footer*/
.footer {
  background-color: #f4f4f4;
  padding: 25px 0;
  border-top: 1px solid #dbdbdb;
}

.footer a {
  color: #0066b3;
  text-decoration: none;
}

footer p {
  font-size: 15px;
  color: #424040;
  padding-bottom: 0px;
  margin-bottom: 8px;
}

.footer_ul_amrc {
  margin: 0px;
  list-style-type: none;
  font-size: 14px;
  padding: 0px 0px 10px 0px;
}

.footer_ul_amrc li {
  padding: 0px 0px 10px 0px;
  color: #fff;
}

.footer_ul_amrc li a {
  color: #000;
}

.footer_ul_amrc li a:hover {
  color: #0ecc53;
}

.inuput-box label {
  color: #5b5b5b;
}

label.color-add {
  color: #5b5b5b;
}

foo footer p {
  color: #000;
}

footer h5 {
  padding: 10px 0;
  color: #000;
}

.number-tnput input {
  padding: 7px 13px;
  border-radius: 5px;
  border: 0;
  margin-bottom: 10px;
  width: 100%;
  font-size: 14px;
}

.number-tnput a {
  background-color: #0ecc53;
  padding: 10px 30px;
  width: 100%;
  display: block;
  text-align: center;
  color: #fff;
}

.ftr-privacy ul li {
  display: inline-block;
  padding: 0px 15px;
  border-right: 1px solid #ccc;
  font-size: 14px;
}

.footer_ul_amrc li {
  padding: 0px 0px 10px 0px;
  color: #fff;
}

.footer_ul_amrc li a i {
  padding-right: 15px;
}

.bottom_border {
  border-bottom: 1px solid #d7d6d6;
  padding-bottom: 20px;
}

/* Style the tab */
.tab {
  display: inline-block;
}

/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 6px 16px;
  transition: 0.3s;
  font-size: 15px;
  border-bottom: 2px solid #fff;
  position: relative;
}

.tab button img {
  display: none;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
}

/* Create an active/current tablink class */
.tab button.active {
  color: #058634;
  /* #058634;*/
  border-bottom: 2px solid #ccc;
}

.tab button.active img {
  position: absolute;
  bottom: -10px;
  left: 45%;
  display: block;
}

/* Style the tab content */
.tabcontent {
  display: block;
  border-top: none;
}

.select-plan {
  display: block;
  margin-bottom: 10px;
  padding: 25px;
  border-radius: 7px;
  border: 1px solid #ccc;
  background-color: white;
}

.select-plan .fa-lock {
  color: green;
  font-size: 19px;
}

.select-plan.digi {
  display: block;
  margin-bottom: 10px;
  padding: 0px;
  border-radius: 0px;
  border: 0px solid #ccc;
  background-color: #f7f7f7;
}

.select-plan.terms {
  background-color: none;
  display: block;
  margin-bottom: 15px;
  padding: 0px;
  border-radius: 0px;
  border: 0px solid #ccc;
}

.br-0 {
  border-radius: 0px !important;
}

.select-plan.terms .main {
  padding-left: 35px;
  margin-bottom: 5px;
}

.select-plan.terms p {
  margin-bottom: 5px;
}

.select-plan a:hover,
.select-plan a:focus {
  background-color: #04b646;
  color: #fff;
}

/* Hide the default checkbox */
.select-plan.terms input[type="checkbox"] {
  visibility: hidden;
  position: absolute;
}

.select-plan h6 {
  color: #000;
  font-size: 18px;
}

.select-plan .line-1 {
  padding: 7px 0;
}

.select-plan .name-add {
  display: inline-block;
  width: 78%;
}

.select-plan .amount-rit {
  display: inline-block;
  width: 21%;
  text-align: right;
}

.ruppie {
  text-decoration: none;
  text-align: end;
  color: #414141;
  font-size: 24px;
  font-weight: 600;
  position: absolute;
  right: -10px;
  top: -21px;
}

.apply-btn {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #4a4a4a;
  padding: 7px 15px;
  z-index: 999;
  text-decoration: none;
  color: #fff;
}

.apply-btn:hover {
  background-color: #000;
  color: #fff;
}

.inuput-box input.c-code[type="text"] {
  border: 0px;
  border: 1px solid #c0c0c0;
  padding: 8px 115px 8px 8px;
  width: 100%;
  box-sizing: border-box;
  /* margin-bottom: 20px; */
  font-size: 14px;
}

.border-t {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.grand-total {
  font-size: 25px;
  font-weight: 700;
}

.package {
  padding: 40px 0;
}

.package .pakage-main {
  padding: 15px 30px;
  border-radius: 8px;
  background-color: #3f3f3f;
  color: #fff;
  width: 100%;
  transition: 0.3s;
  margin-bottom: 15px;
  min-height: 248px;
}

.package .pakage-main:hover {
  box-shadow: 0px 0px 8px rgb(64, 64, 64);
}

.package .pakage-main .amount {
  font-size: 50px;
  font-weight: 700;
}

.package .pakage-main .amount span {
  font-size: 30px;
  font-weight: 300;
  vertical-align: top;
  padding-right: 5px;
}

.package .pakage-main .amount sub {
  font-size: 15px;
  font-weight: 300;
}

.package .pakage-main .offer {
  text-align: center;
  font-weight: 300;
  padding: 30px 0;
  font-size: 17px;
}

.package .pakage-main .offer span {
  font-size: 13px;
}

.package .pakage-main.green {
  background-color: #2b5139;
}

.package .get {
  padding: 16px 34px;
  display: inline-block;
  background-color: #1bc65a;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #12ae4b;
}

.whit {
  color: #fff;
}

.main {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 20px;
}

/* Hide the default checkbox */
.select-plan input[type="checkbox"] {
  visibility: hidden;
  top: 0;
}

/* Creating a custom checkbox
based on demand */
.geekmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border: 1px solid #535353;
  border-radius: 3px;
}

/* Creating a custom checkbox
based on demand */
.sub-add .geekmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border: 1px solid #535353;
}

/* Specify the background color to be
shown when hovering over checkbox */
.main:hover input~.geekmark {
  background-color: #82c46a;
}

/* Specify the background color to be
shown when checkbox is active */
.main input:active~.geekmark {
  background-color: #82c46c;
}

/* Specify the background color to be
shown when checkbox is checked */
.main input:checked~.geekmark {
  background-color: green;
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.main input:checked~.geekmark:after {
  display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
showing only two border to make it look
like a tickmark */
.main .geekmark:after {
  left: 10px;
  bottom: 10px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.show-inp {
  position: relative;
}

/* Hide the default checkbox */
.show-inp input[type="checkbox"] {
  visibility: hidden;
  top: 0;
}

/* Creating a custom checkbox
based on demand */

/* Specify the background color to be
shown when hovering over checkbox */
.show-inp .sub-add:hover input~.geekmark {
  background-color: #82c46a;
}

/* Specify the background color to be
shown when checkbox is active */
.show-inp .sub-add input:active~.geekmark {
  background-color: #82c46c;
}

/* Specify the background color to be
shown when checkbox is checked */
.show-inp .sub-add input:checked~.geekmark {
  background-color: green;
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Display checkmark when checked */
.show-inp .sub-add input:checked~.geekmark:after {
  display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
showing only two border to make it look
like a tickmark */
.show-inp .sub-add .geekmark::after {
  left: 6px;
  bottom: 5px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-top-width: medium;
  border-right-width: medium;
  border-bottom-width: medium;
  border-left-width: medium;
  border-width: 0px 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.show-inp .sub-add input[type="checkbox"] {
  visibility: hidden;
  top: 0;
  position: absolute;
  left: 0;
}

.show-inp label.sub-add {
  font-size: 14px;
  padding-left: 25px;
}

.info-btn {
  display: inline-block;
  font-size: 18px;
}

.info-btn:hover>.tooltiptext-fname {
  display: block;
}

.info-btn i {
  color: #118403;
  padding-left: 5px;
}

.inuput-box img.info-img {
  display: none;
}

.hover-img {
  width: 100%;
  position: relative;
}

.info-btn a {
  display: block;
}

.info-img {
  display: none;
}

.info-p {
  display: none;
}

.info-btn a:hover>.info-img {
  display: block;
  position: absolute;
  right: 0px;
  top: 33px;
  z-index: 1;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.info-btn a:hover>.info-p {
  display: block;
  position: absolute;
  top: 33px;
  z-index: 999;
  padding: 5px 7px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 4px;
  color: #000;
}

.dropbtn {
  padding: 7px 14px;
  border: 1px solid #c4c3c3;
  font-size: 14px;
  background-color: #fff;
  width: 100%;
  text-align: left;
  background-image: url(../img/arro-down.png);
  background-position: right 13px center;
  background-repeat: no-repeat;
}

.dropbtn:hover,
.dropbtn:focus {
  background-color: #fff;
}

#myInput {
  box-sizing: border-box;
  /* background-image: url(../img/searchicon.png); */
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 16px;
  padding: 14px 20px 12px 45px;
  border: none;
  border-bottom: 1px solid #ddd;
}

#myInput:focus {
  outline: 3px solid #ddd;
}

.dropdown {
  position: relative;
  width: 100%;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f6f6f6;
  min-width: 230px;
  overflow: auto;
  border: 1px solid #ddd;
  z-index: 1;
  width: 100%;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown a:hover {
  background-color: #ddd;
}

.show {
  display: block;
  width: 100%;
}

.dropdown .file-type {
  padding: 10px 7px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
}

.dropdown .file-type label {
  display: block;
}

.video-btn {
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
}

.video-btn a {
  padding: 5px 15px;
  border-radius: 5px;
  background-color: #039b3b;
  display: inline-block;
  color: #fff;
  font-size: 15px;
  font-weight: 300;
}

.video-btn span {
  padding: 7px 15px;
  border: 1px solid #ccc;
  margin-right: 20px;
}

.video-btn span.active {
  padding: 7px 15px;
  border: 1px solid #ccc;
  background-color: #4f4f4f;
  margin-right: 20px;
  color: #fff;
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 2rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 30px;
  border: 1px solid #e1e0e0;
}

.progress .progress-bar-success {
  background-color: #b0263a;
}

.container1 {
  margin: 10px auto;
  width: 100%;
  text-align: center;
}

.container1 .progress {
  margin: 0 auto;
  width: 100%px;
}

.progress {
  padding: 4px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar {
  height: 21px;
  border-radius: 4px;
  background-image: linear-gradient(to bottom,
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;
  transition-property: width, background-color;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.25),
    inset 0 1px rgba(255, 255, 255, 0.1);
}

/*
 * Note: using adjacent or general sibling selectors combined with
 *       pseudo classes doesn't work in Safari 5.0 and Chrome 12.
 *       See this article for more info and a potential fix:
 *       https://css-tricks.com/webkit-sibling-bug/
 */

#five:checked~.progress>.progress-bar {
  width: 1%;
  background-color: #f63a0f;
}

#twentyfive:checked~.progress>.progress-bar {
  width: 35%;
  background-color: #f27011;
}

#fifty:checked~.progress>.progress-bar {
  width: 75%;
  background-color: #f2b01e;
}

#onehundred:checked~.progress>.progress-bar {
  width: 100%;
  background-color: #86e01e;
}

.radio {
  display: none;
}

.label {
  display: inline-block;
  margin: 0 5px 20px;
  padding: 3px 8px;
  color: #aaa;
  border-radius: 3px;
  cursor: pointer;
}

.radio:checked+.label {
  color: white;
  background: rgba(0, 0, 0, 0.25);
}

.btn-file {
  position: relative;
  overflow: hidden;
  height: 200px;
  width: 100%;
}

.btn-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
  cursor: pointer;
}

.btn-default.btn-file {
  background-color: #fff;
  border-radius: 7px;
  border: 2px dashed #cccc;
}

.show-me {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  text-align: center;
  padding-top: 30%;
  color: #fff;
}

.btn-default.btn-file:hover>.show-me {
  display: block;
}

.close-btn a {
  position: absolute;
  right: 10px;
  top: 10px;
  display: inline-block;
  color: #f66464;
}

.fileList li {
  list-style: none;
  padding: 7px;
  margin-top: 7px;
  border-radius: 7px;
  border: 1px solid #118403;
}

.fileList a {
  float: right;
}

.info-ad {
  position: absolute;
  left: 0;
  bottom: -2px;
  color: red;
  font-size: 13px;
}

.sub-head {
  font-size: 19px;
}

.list-style-add {
  padding: 0;
  margin: 0;
}

.list-style-add li {
  padding-left: 35px;
  padding-bottom: 20px;
  margin: 0;
  list-style: none;
  background-image: url(../img/active.png);
  background-position: left top;
  background-repeat: no-repeat;
}

.e-sign {
  display: block;
  padding: 13px 10px;
  text-align: center;
  font-size: 17px;
  background-color: #464646;
  color: #fff;
  border-radius: 5px;
}

.e-sign:hover {
  background-color: #018231;
  color: #fff;
}

.courier {
  display: block;
  padding: 13px 10px;
  text-align: center;
  font-size: 17px;
  border: 1px solid #464646;
  color: #464646;
  border-radius: 5px;
}

.courier:hover {
  border: 1px solid #018231;
  color: #04b646;
}

.text-justify {
  text-align: justify;
}

.box-ad {
  text-align: justify;
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  min-height: 187px;
}

.camerta {
  padding: 30px 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 2px dashed #acacac;
  box-sizing: border-box;
  min-height: 187px;
}

/* change by developer */
.files select {
  /* width: 100%;
	border: 0;
	background-color: inherit; */
  width: 88%;
  border: 0;
  background-color: inherit;
  display: inline-block;
  /* background: #fff; */
}

.download-link {
  display: inline-block;
  float: right;
  font-size: 14px;
  padding-top: 5px;
}

.list-ad {
  text-align: justify;
  padding-left: 18px;
}

.list-ad li {
  padding: 0;
  font-size: 12px;
}

.clr-d {
  color: #000;
  padding-bottom: 15px;
}

.show-inp {
  display: inline-block;
  padding-right: 10px;
}

.show-inp input[type="checkbox"] {
  visibility: visible;
}

.show-inp label {
  font-size: 14px;
  padding-left: 7px;
}

.ajax-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 999;
}

.ajax-loader img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.head-ov {
  font-size: 20px;
}

.thank {
  font-size: 25px;
}

.digi-span {
  color: #6a42fe;
}

.digi-btn {
  padding: 13px 12px;
  border-radius: 6px;
  background-color: #6a42fe;
  color: #fff;
  display: block;
  text-align: center;
  text-decoration: none;
}

.digi-btn:hover {
  background-color: #5530dd;
  color: #fff;
}

.confrm label {
  display: inline-block;
  font-size: 14px;
  font-weight: 300;
  color: #555555;
}

.font15 {
  font-size: 15px;
}

.confrm input[type="checkbox"] {
  margin-right: 5px;
}

.phot-frame a {
  display: block;
  text-align: center;
  border-radius: 5px;
  background-color: #3bb54a;
  padding: 25px 0;
  color: #fff;
  font-size: 25px;
  margin-top: 10px;
}

.phot-frame a:hover {
  background-color: #3a3b3a;
}

.phot-frame i {
  font-size: 50px;
  padding-bottom: 20px;
}

.otp {
  color: #000;
  border: 2px dashed #848484;
  padding: 7px 15px;
}

.bg-w {
  background-color: #fff;
  border-radius: 10px;
  padding: 25px;
  box-sizing: border-box;
}

.thankp {
  font-size: 16px;
}

.mt-6 {
  margin-top: 2px;
}

.mt-10 {
  margin-top: 35%;
}

#font10 {
  color: #fff;
}

.address {
  height: 100%;
}

/* ------------------------------ Responsive Media Styles ---------------------------- */

@media only screen and (min-width: 1200px) and (max-width: 1920px) {}

@media only screen and (min-width: 1200px) {}

@media only screen and (min-width: 992px) and (max-width: 1199px) {}

@media only screen and (max-width: 991px) {}

@media only screen and (min-width: 768px) and (max-width: 991px) {}

@media only screen and (max-width: 767px) {
  .logo-align {
    text-align: center;
  }

  .col-md-50 {
    display: block;
    width: 100%;
    vertical-align: top;
  }

  .images-add {
    display: none;
  }

  .ruppie {
    text-decoration: none;
    color: #414141;
    font-size: 24px;
    font-weight: 600;
    position: inherit;
    right: -0px;
    top: 1px;
  }

  .pt-55 {
    padding-top: 25px;
  }

  .address {
    height: 80vh;
  }

  .scrollY {
    height: 80vh !important;
  }
}

@media only screen and (min-width: 760px) and (max-width: 769px) {
  .font10 {
    font-size: 10px !important;
  }

  #font10 {
    font-size: 10px !important;
  }

  .pt-6 {
    margin-top: 0px;
  }

  .tp-6 {
    font-size: 10px;
  }
}

@media only screen and (min-width: 770px) and (max-width: 929px) {
  .font10 {
    font-size: 10px !important;
  }

  #font10 {
    font-size: 10px !important;
  }

  .pt-6 {
    margin-top: 0px;
  }

  .tp-6 {
    margin-top: 0px;
    font-size: 10px;
  }
}

@media only screen and (max-width: 479px) {}

/* developers extra styles */
/* mobile number text  and extra added Styles*/
.s-box .inp input.mob[type="number"] {
  border: 1px solid #c0c0c0;
  padding: 8px 38px 8px 50px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  /* margin-bottom: 20px; */
  font-size: 14px;
}

.s-box .inp input.mob[type="number"]:focus {
  border: 1px solid #0066b3;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* /number/ */
.s-box .inp input[type="number"] {
  border: 1px solid #c0c0c0;
  padding: 8px 8px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  /* margin-bottom: 20px; */
  font-size: 14px;
}

.s-box .inp input[type="number"]:focus {
  border: 1px solid #000;
}

/* UPDATION IN 16-4-2021 */
.paddingleft {
  padding-left: 5px;
}

.radio-box .paddingleft {
  padding-left: 10px;
}

.errorfont {
  font-size: 12px;
}

.edit-btn1 {
  position: absolute;
  right: 5px;
  top: 18px;
  font-size: 18px;
}

.edit-btn1 a {
  background-color: inherit;
  padding: 0px;
}

.edit-btn1 a:hover {
  background-color: inherit;
  padding: 0px;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50px;
}

.dropdownList {
  font-size: 12px;
  line-height: 4px;
  color: gray;
}

.loaderContainer {
  position: absolute;
  top: 0px;
}

.removeunderline {
  text-decoration: none;
}

.createpassword-tope {
  top: 5px;
}

/* google edit plugin */
.google button span {
  width: 100px;
  text-align: center;
  margin-left: -10px;
  inline-size: fit-content;
}

.google button svg {
  text-align: center;
  margin-left: 126px;
}

@media only screen and (max-width: 767px) {
  .google button svg {
    text-align: center;
    margin-left: 40px;
  }
}

@media only screen and (min-width: 770px) and (max-width: 929px) {
  .font10 {
    font-size: 11px !important;
  }

  #font10 {
    font-size: 11px !important;
  }

  .pt-6 {
    margin-top: 2px;
  }

  .tp-6 {
    margin-top: -2.5px;
  }
}

@media only screen and (min-width: 760px) and (max-width: 769px) {
  .font10 {
    font-size: 10px !important;
  }

  #font10 {
    font-size: 10px !important;
  }

  .pt-6 {
    margin-top: 2px;
  }

  .tp-6 {
    margin-top: -3px;
  }
}

.inpersonimgheight {
  height: 100%;
  width: 100%;
}

.textuppercase {
  text-transform: uppercase;
}

.ipvImage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 4px;
}

.paninput {
  text-transform: uppercase;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}

::placeholder {
  /* Recent browsers */
  text-transform: none;
}

.inuput-box .react-datepicker-wrapper {
  display: block;
  padding: 0;
  border: 0;
}

.inuput-box button.react-datepicker__navigation {
  background-color: none;
  color: #000;
}

.show-inp {
  position: relative;
}

.bank_pencil a:hover {
  color: #007bff;
  text-decoration: none;
}

.bank_pencil a {
  color: black;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

.bank_pencil p {
  display: "inline-block";
  margin-bottom: 0;
}

.peneimgsrcemail {
  padding-top: 46px;
}

.radio-box input[type="radio"] {
  display: none;
}

.radio-box input[type="radio"]+label {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-right: 10px;
}

.radio-box input[type="radio"]+label:before {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 50%;
  margin-right: 5px;
  width: 16px;
  margin-top: 3px;
  height: 16px;
  border: 1px solid #ccc;
  background: #fff;
}

.radio-box input[type="radio"]:checked+label:before {
  border: 1px solid green;
}

.radio-box input[type="radio"]:disabled+label:before {
  border: 1px solid gray;
}

.radio-box input[type="radio"]:disabled+label:after {
  background: gray;
}

.radio-box input[type="radio"]+label:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background: green;
  position: absolute;
  border-radius: 50%;
  top: 6px;
  left: 3px;
  opacity: 0;
  transform: scale(0, 0);
  /* transition: all .2s cubic-bezier(0.64, 0.57, 0.67, 1.53); */
}

.radio-box input[type="radio"]:checked+label:after {
  opacity: 1;
  transform: scale(1, 1);
}

/* paid */
.paid {
  position: absolute;
  left: 33vw;
  top: 38%;
  z-index: 999999;
}

@media only screen and (max-width: 700px) {
  .paid {
    top: 25%;
  }
}

.uploadsdropdonpadding {
  padding-top: 25px;
}

.mandatory-border {
  border: 2px dashed red !important;
}

.upload-message {
  color: red;
  position: relative;
  bottom: 20px;
}

.upload-types {
  color: #3ba72e;
  font-size: 12px;
  position: relative;
  bottom: 40px;
}

.upload-title {
  padding-left: 4px;
  font-weight: 500;
  font-size: 18px;
}

.inuput-box list.list-group.dropdownList {
  position: absolute;
  width: 100%;
  z-index: 99;
}

.list-group-item:focus,
.list-group-item:hover {
  z-index: 1;
  text-decoration: none;
  background-color: #008000;
  color: #fff;
}

.inuput-box .list-group-item {
  padding: 7px 10px;
  line-height: 24px;
}

.signinpencil {
  /* padding-top: 39px; */
  padding-top: 62px;
}

.required {
  color: red;
}

.app-btn a {
  display: inline-block;
  width: 170px;
  padding-right: 20px;
  box-sizing: border-box;
}

[id^="parentdigio-ifm"] {
  position: fixed !important;
}

.popup-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popup-bg .box-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  background-color: #fff;
  border-radius: 7px;
}

@media screen and (max-width: 1100px) {
  .pl-20 {
    padding-left: 20px !important;
  }
}

@media screen and (max-width: 1224px) {
  .popup-bg .box-section {
    width: 360px !important;
  }
}

.popup-bg .box-section .header-area {
  padding: 15px 10px;
  border-bottom: 1px solid #ccc;
  font-weight: 500;
  font-size: 20px;
  color: #c81f1f;
}

.popup-bg .box-section .contents-area {
  padding: 15px 10px;
  border-bottom: 1px solid #ccc;
}

.popup-bg .box-section .btn-area {
  padding: 15px 10px;
}

.popup-bg .box-section .btn-area a {
  display: inline-block;
  padding: 7px 20px;
  border-radius: 5px;
  background-color: #c81f1f;
  color: #fff;
}

.popup-bg .box-section .btn-area a.cancel {
  display: inline-block;
  padding: 7px 20px;
  border-radius: 5px;
  background-color: #ccc;
  color: #000;
}

.popup-bg .box-section .btn-area a:hover {
  background-color: #e54d4d;
  color: #fff;
}

.popup-bg .box-section .btn-area a.cancel:hover {
  background-color: #848383;
}

.aadhar-popup-bg {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.aadhar-popup-bg .box-section {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: #fff;
  border-radius: 7px;
}

@media screen and (max-width: 1224px) {
  .aadhar-popup-bg .box-section {
    width: 360px !important;
  }
}

.aadhar-popup-bg .box-section .header-area {
  padding: 16px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: 500;
  font-size: 18px;
  color: #000;
  position: relative;
}

.aadhar-popup-bg .box-section .contents-area {
  padding: 8px 24px;
  /* border-bottom: 1px solid #ccc; */
}

@media screen and (max-width: 1224px) {
  .aadhar-popup-bg .box-section .contents-area {
    padding: 8px 8px !important;
  }
}

.aadhar-popup-bg .box-section .contents-area input {
  font-size: 16px;
  display: inline-block;
  width: 30%;
  margin: 0 3px;
  padding: 5px 10px;
  border: 1px solid #ccc;
}

.aadhar-popup-bg .box-section .contents-area input:focus {
  border: 1px solid #137313;
}

.aadhar-popup-bg .box-section .btn-area {
  padding: 15px 10px;
}

.aadhar-popup-bg .box-section .btn-area a {
  display: inline-block;
  padding: 7px 20px;
  border-radius: 5px;
  background-color: #137313;
  color: #fff;
}

.aadhar-popup-bg .box-section .btn-area a.cancel {
  display: inline-block;
  padding: 7px 20px;
  border-radius: 5px;
  background-color: #ccc;
  color: #000;
}

.aadhar-popup-bg .box-section .btn-area a:hover {
  background-color: #096309;
  color: #fff;
}

.aadhar-popup-bg .box-section .btn-area a.cancel:hover {
  background-color: #848383;
}

.alertBox-a {
  text-decoration: none;
}

.alertbox_message {
  color: #0000008a !important;
  font-size: 16px !important;
  margin-bottom: 12px !important;
  padding: 15px 24px !important;
}

.alertbox_title {
  color: #000000de !important;
}

.aadhar-popup-bg .box-section .btn-area1 {
  padding: 9px 9px;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
}

.aadhar-popup-bg .box-section .btn-area1 a {
  display: inline-block;
  padding: 6px 8px;
  color: #137313;
  font-weight: 500;
  font-size: 14px;
}

.aadhar-popup-bg .box-section .btn-area1 a.cancel {
  display: inline-block;
  padding: 6px 8px;
  color: #000;
  font-weight: 500;
  font-size: 14px;
}

.aadhar-popup-bg .box-section .btn-area1 a.cancel:hover {
  color: #000;
  background-color: rgba(40, 167, 69, 0.04);
}

.aadhar-popup-bg .box-section .btn-area1 a:hover {
  background-color: rgba(40, 167, 69, 0.04);
  color: #137313;
  cursor: pointer;
}

.aadhar-popup-bg .box-section .header-area {
  border-bottom: none !important;
}

.aadhar-input {
  width: 110px !important;
  letter-spacing: 0.8em;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

@media screen and (max-width: 1224px) {
  .aadhar-input {
    width: 110px !important;
    margin-left: 0px !important;
    margin-right: 2px !important;
  }
}

.add-classpopup {
  margin-top: 24px;
}

.tooltiptext-fname {
  /* background-color: black;
	color: #fff; */
  /* color:black;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	font-size: 12px;
	width: 80%;
	position: absolute;
	z-index: 1;
	display: none; */

  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 12px;
  width: 76%;
  position: absolute;
  z-index: 1;
  display: none;
  top: 0;
  left: 100px;
  background-color: #fff;
  padding: 7px;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 20%);
}

.inp1 .button1 {
  background-color: #04b646 !important;
  padding: 12px 55px !important;
  text-align: center !important;
  border-radius: 3px !important;
  color: #fff !important;
  text-decoration: none !important;
  box-sizing: border-box !important;
  border: 0 !important;
  cursor: pointer !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
}

.inp1 .button2 {
  background-color: #989797 !important;
  padding: 12px 55px !important;
  text-align: center !important;
  border-radius: 3px !important;
  color: #fff !important;
  text-decoration: none !important;
  box-sizing: border-box !important;
  border: 0 !important;
  cursor: pointer !important;
  font-size: 16px !important;
  text-transform: uppercase !important;
}

.inp1 {
  display: block;
}

.icon-topemail {
  top: -25px !important;
}

.icon-topsignin {
  top: -42px !important;
}

.coupenerrormessage {
  font-size: 12px !important;
  margin-left: 5px !important;
  margin-top: 5px !important;
}

.api-errormessage {
  font-size: 12px !important;
}

.mh-25 {
  min-height: 25px;
}

.mh-42 {
  min-height: 42px;
}

.mh-64 {
  min-height: 64px;
}

.mh-18 {
  height: 18px;
}

.mh-7 {
  height: 7px;
  line-height: 20px;
}

.top-5 {
  top: 5px !important;
}

.f-1 {
  flex: 1;
}

.accordion {
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 18px;
  font-weight: 700;
  transition: 0.4s;
  border-radius: 4px;
  display: flex;
}

button.expand:after {
  content: "\002B";
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

button.coll:after {
  content: "\2212";
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active,
.accordion:hover {
  background-color: #fff;
}

.panel {
  padding: 0 18px;
  display: block;
  background-color: white;
  overflow: hidden;
}

datalist {
  background-color: white;
}

datalist option {
  font-size: 0.8em;
  padding: 0.3em 1em;
  background-color: white;
  cursor: pointer;
  color: black;
}

.button-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: #0066b3;
  display: inline;
  margin: 0;
  padding: 0;
}

.vh {
  visibility: hidden;
}

.nom-share {
  width: 13vw;
}

/* New Timeline style */
.vertical-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}

.vertical-container::after {
  /* clearfix */
  content: "";
  display: table;
  clear: both;
}

#vertical-timeline {
  position: relative;
  padding: 0;
  margin-top: 2em;
  margin-bottom: 2em;
}

#vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #f1f1f1;
}

.vertical-timeline-content .btn {
  float: right;
}

#vertical-timeline.light-timeline:before {
  background: #e7eaec;
}

@media only screen and (min-width: 1170px) {
  #vertical-timeline.center-orientation {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  #vertical-timeline.center-orientation:before {
    left: 50%;
    margin-left: -2px;
  }
}

@media only screen and (max-width: 1170px) {
  .center-orientation.dark-timeline .vertical-timeline-content:before {
    border-color: transparent #f5f5f5 transparent transparent;
  }
}

.vertical-timeline-block {
  position: relative;
  margin: 1em 0;
}

.vertical-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}

.vertical-timeline-block:first-child {
  margin-top: 0;
}

.vertical-timeline-block:last-child {
  margin-bottom: 0;
}

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-block {
    margin: 4em 0;
  }

  .center-orientation .vertical-timeline-block:first-child {
    margin-top: 0;
  }

  .center-orientation .vertical-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.vertical-timeline-icon {
  position: absolute;
  top: 0;
  left: 0px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 16px;
  border: 3px solid #f1f1f1;
  text-align: center;
}

.vertical-timeline-icon i {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -9px;
}

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-icon {
    width: 50px;
    height: 50px;
    left: 50%;
    margin-left: -25px;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    font-size: 19px;
  }

  .center-orientation .vertical-timeline-icon i {
    margin-left: -12px;
    margin-top: -10px;
  }

  .center-orientation .cssanimations .vertical-timeline-icon.is-hidden {
    visibility: hidden;
  }
}

.vertical-timeline-content {
  position: relative;
  margin-left: 60px;
  background: white;
  border-radius: 0.25em;
  padding: 8px 0px 15px 0px;
}

.vertical-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}

.vertical-timeline-content h2 {
  font-weight: 400;
  margin-top: 4px;
  text-align: left;
}

.vertical-timeline-content p {
  margin: 1em 0;
  line-height: 1.6;
}

.vertical-timeline-content .vertical-date {
  float: left;
  font-weight: 500;
}

.vertical-date small {
  color: #1ab394;
  font-weight: 400;
}

.vertical-timeline-content::before {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid white;
}

@media only screen and (min-width: 768px) {
  .vertical-timeline-content h2 {
    font-size: 16px;
    color: #000;
    text-align: left;
  }

  .vertical-timeline-content.not-act h2 {
    color: #808080;
  }

  .vertical-timeline-content p {
    font-size: 13px;
  }
}

@media only screen and (min-width: 1170px) {
  .center-orientation .vertical-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }

  .center-orientation .vertical-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: white;
  }

  .center-orientation .vertical-timeline-content .btn {
    float: left;
  }

  .center-orientation .vertical-timeline-content .vertical-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 2px;
    font-size: 14px;
  }

  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content {
    float: right;
  }

  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: white;
  }

  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .btn {
    float: right;
  }

  .center-orientation .vertical-timeline-block:nth-child(even) .vertical-timeline-content .vertical-date {
    left: auto;
    right: 122%;
    text-align: right;
  }

  .center-orientation .cssanimations .vertical-timeline-content.is-hidden {
    visibility: hidden;
  }
}

#vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 95%;
  width: 4px;
  background: #888;
}

.navy-bg,
.bg-primary {
  background-color: #04b646 !important;
  color: #ffffff;
}

.lazur-bg,
.bg-info {
  background-color: #dbdbdb !important;
  color: #ffffff;
}

.yellow-bg,
.bg-warning {
  background-color: #b50101 !important;
  color: #ffffff;
}

.ipv-section {
  margin-left: 0rem;
  margin-right: 0rem;
}

@media screen and (max-width: 1224px) {
  .ipv-section {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .app-btn a {
    width: 130px !important;
  }
}

.panName .box-section {
  width: 700px !important;
  padding-left: 8px;
}

.panName .digi-btn {
  padding: 8px 12px !important;
  border-radius: 6px;
  background-color: primary !important;
}

.notecss {
  color: black;
  font-weight: 600;
}

/*footer*/

.site-footer {
  background: #f8f9fa;
  color: #000;
  width: 100%;
}

.site-footer a {
  color: #0066b3;
}

.site-footer a:hover {
  color: #0066b3;
}

.straph {
  color: #fff;
  background-color: #04b646;
  font-weight: 300;
  padding: 10px 0;
}

.s-icin {
  display: inline-block;
}

#font10 {
  color: #fff;
}

.pvt-links {
  background-color: #002a4a;
  text-align: center;
  color: #000;
  padding: 15px 0;
}

.copy-r {
  background-color: #f8f9fa;
}

.portal {
  margin-top: -50px;
  background-color: #f8f9fa;
  padding: 25px;
}

@media (min-width: 768px) {
  .site-footer {
    color: #000;
  }
}

.s-icin {
  display: inline-block;
}

.site-footer .border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.site-footer p {
  color: #000;
}

.site-footer h2,
.site-footer h3,
.site-footer h4,
.site-footer h5 {
  color: #000;
}

.site-footer ul li {
  margin-bottom: 10px;
  list-style: none;
}

.site-footer .footer-heading {
  font-size: 20px;
  color: #000;
  font-weight: 300;
  background-image: url(../img/bg-f.png);
  background-position: left bottom;
  background-repeat: no-repeat;
  padding-bottom: 6px;
}

/*brokerage table*/
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.tbltbody:nth-child(even) {
  background-color: rgba(238, 235, 235, 0.966);
}

.tbltbody:nth-child(odd) {
  background-color: transparent;
}

.tblhead {
  background-color: #0083e6 !important;
  color: white;
  text-align: center;
}

.overflow-auto {
  overflow: auto;
}

.mr-10 {
  margin-right: 16rem !important;
}

.h-178 {
  height: 178px !important;
}

.w-280 {
  width: 280px !important;
}

.-mt-2 {
  margin-top: -2px !important;
}

.color-blue {
  color: #0466b0;
}

.cursor-pointer {
  cursor: pointer;
}

.know-more-box-section {
  width: 660px !important;
}

.know-more-box-section .cancel-button button {
  color: #0466b0;
  margin-right: 16px;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.closure-reasons {
  padding-top: 12px;
  padding-left: 16px;
}

div ol {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  color: #555555;
  font-weight: 300;
  line-height: 26px;
  margin: 0 0 15px 0;
}

.text-md {
  font-size: 1rem !important;
}

.alertContainer {
  position: absolute;
  top: -25%;
  left: -40%;
}

.info-button {
  display: inline-block;
  font-size: 20px;
}

.info-button i {
  color: green;
}

.-mb-3 {
  margin-bottom: -20px;
}

.mybutton {
  position: relative;
  z-index: 999;
}

.f-20 {
  font-size: 25px !important;
}

.mr-6 {
  margin-right: 11rem;
}

.w-10 {
  width: 101% !important;
  height: 101% !important;
}

[list]::-webkit-calendar-picker-indicator {
  display: none !important;
}

.mf-radio-box input[type="radio"] {
  display: none;
}

.mf-radio-box input[type="radio"] {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-right: 10px;
}

.mf-radio-box input[type="radio"]:before {
  content: "";
  display: block;
  position: absolute;
  top: -2px;
  left: -1px;
  border-radius: 50%;
  margin-right: 5px;
  width: 16px;
  margin-top: 0px;
  height: 16px;
  border: 1px solid #ccc;
  background: #fff;
}

.mf-radio-box input[type="radio"]:checked:before {
  border: 1px solid green;
}

.mf-radio-box input[type="radio"]:disabled:before {
  border: 1px solid gray;
}

.mf-radio-box input[type="radio"]:disabled:after {
  background: gray;
}

.mf-radio-box input[type="radio"]:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background: green;
  position: absolute;
  border-radius: 50%;
  top: 1px;
  left: 2px;
  opacity: 0;
  transform: scale(0, 0);
}

.mf-radio-box input[type="radio"]:checked:after {
  opacity: 1;
  transform: scale(1, 1);
}

.aadhar-btn button {
  padding: 10px 15px !important;
  margin-top: -10px;
  font-size: 14px;
}

.aadhar-btn button:hover {
  color: #fff !important;
}

.justify-center {
  justify-content: center;
  display: flex;
}

.bg-red {
  border: 1px solid red !important;
}
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  font-family: "Inter", sans-serif !important;
  overflow-x: hidden;
  max-width: 100%;
  height: auto;
}

.logoutz {
  font-size: 20px;
  color: rgb(24, 129, 209);
}

.client_id {
  margin-left: 25%;
  margin-right: -30%;
}

.section1 {
  margin-left: 15%;
}

.section2 {
  margin-left: 15%;
  margin-top: 2.5%;
}

.second_label {
  margin-left: 25.2%;
}

.forth_label {
  margin-left: 22%;
}

.fifth_label {
  margin-left: 23%;
}

.acumen-logo {
  width: 20%;
}

.select1 {
  width: 450px;
  padding: 5px;
  margin-left: 1%;
}

.select2 {
  margin-left: 2%;
  padding: 5px;
  margin-right: -10%;
}

.date1 {
  width: 250px;
  padding: 3px;
  padding-left: 7px;
  margin-left: 25px;
}

.first_section {
  display: flex;
  margin-left: 15%;
  flex-direction: row;
  justify-content: flex-start;
}

.disable1 {
  border: none;
  background-color: transparent;
}

.disflt {
  margin-left: auto;
  margin-right: auto;
}

.hi_name {
  font-size: 20px;
}

.radio1 {
  display: flex;
}

.radio2 {
  margin-top: -2%;
  margin-left: 1%;
  margin-right: -10%;
  padding-top: 10px;
  border: none !important;
}

.radio3 {
  display: flex;
  margin-left: 25.7%;
}

.mobs {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
}

.inpt {
  background-color: #04b646;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
  color: #fff;
  text-decoration: none;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  width: 100%;
  margin-top: 10px;
}

.inpt:hover {
  background-color: #018231;
}

.resends {
  margin-top: -8px;
}

.check1 {
  margin-top: 6%;
  font-size: 12px;
  padding-left: 1px;
}

.check2 {
  width: 60px;
  margin-top: -60px;
  margin-right: 5px;
}

.lblcheck {
  text-align: justify;
  font-family: "Inter", sans-serif;
  padding-right: 1px;
  font-size: 11.5px;
}

.aadhar-popup-bgs {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.box-sections {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: auto;
  background-color: #fff;
  border-radius: 7px;
}

.box-sectionz {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75vw;
  height: 90vh;
  overflow: hidden;
  background-color: #fff;
  border-radius: 7px;
}

.box-section-add {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 475px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 7px;
}

.nominee-box {
  height: 65vh;
  width: 100%;
  overflow-y: scroll;
}

.box-sectionx {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw !important;
  min-height: 375px;
  background-color: #fff;
  border-radius: 7px;
  max-height: 90vh;
}

.scrollY {
  max-height: 78vh !important;
}

.mybutton {
  float: right;
  margin-right: 10px;
  font-size: 25px;
  font-style: normal !important;
}

.mybutton:hover {
  cursor: pointer;
}

/* Tested Ok */
.loaderContainers {
  position: absolute;
  top: 0px;
  left: 0px;
}

.ind {
  font-size: 14px;
  position: absolute;
  left: 2;
  padding: 8px 8px;
  border-right: 1px solid #c0c0c0;
}

.err {
  position: absolute;
  font-size: 12px;
  left: 40px;
  margin-top: 2px;
}

.errs {
  position: absolute;
  font-size: 12px;
  margin-left: 5px;
}

.otperr {
  font-size: 12px;
  left: 40px;
  margin-top: 2px;
}

.mobinputs {
  width: 100%;
  padding: 7px;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

.mobinputs:hover {
  cursor: pointer;
  border: 1px solid #018231;
}

.mobinputs:focus {
  border: 1px solid #018231;
}

.edit-bt {
  position: absolute;
  right: 6%;
  margin-top: -10%;
}

.email-input {
  width: 100%;
  padding: 7px;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

.mobinput {
  width: 100%;
  padding: 7px;
  padding-left: 45px;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
}

.mobinput:hover {
  cursor: pointer;
  border: 1px solid #018231;
}

.mobinput:focus {
  border: 1px solid #018231;
}

.email-input:hover {
  cursor: pointer;
  border: 1px solid #018231;
}

.pincode {
  width: 96%;
}

.email-input:focus {
  border: 1px solid #018231;
}

#pd-50 {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 20px;
}

.otp-timer {
  position: absolute;
  right: 6%;
  font-size: 12px;
  margin-top: -37px;
}

.cancelbtn {
  border: none;
  margin-left: 5px;
  padding: 10px 20px;
  text-align: center;
  background-color: transparent;
  margin-top: 25px;
}

.cancelbtns {
  border: none;
  margin-left: 5px;
  padding: 10px 20px;
  text-align: center;
  background-color: transparent;
  margin-top: 25px;
  color: grey;
}

.inps {
  background-color: #04b646;
  padding: 10px 20px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  text-decoration: none;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  float: right;
  margin-top: 25px;
  margin-right: 1px;
}

.inps:hover {
  background-color: #018231;
}

.vbtns {
  background-color: #04b646;
  padding: 10px 20px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  text-decoration: none;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  font-size: 15px;
  text-transform: uppercase;
  float: right;
}

.vbtns:hover {
  background-color: #018231;
}

.navbars {
  display: flex;
  align-items: center;
  background: white;
  font-family: "Inter", sans-serif;
  font-weight: 300;
}

.navbar__title {
  margin-right: auto;
  font-size: 150%;
}

.navbar__item {
  padding: 16px 16px;
  cursor: pointer;
}

.summarydiv {
  background-color: #f2f6ff;
  padding-top: 50px;
  padding-bottom: 100px;
  font-family: "Inter", sans-serif;
}

.marg {
  margin-left: auto;
  margin-right: auto;
}

.forms {
  background-color: white;
  padding: 30px;
}

.forms:hover {
  cursor: pointer;
}

.formz {
  background-color: #f8f8f8;
  padding-left: 30px;
  padding-right: 30px;
}

.formz:hover {
  cursor: pointer;
}

.mobile-edit {
  margin-left: 12px;
}

.email-edit {
  margin-left: 12px;
}

.flex {
  display: flex;
}

.flexs {
  display: flex;
}

.flexx {
  display: flex;
}

.dselect {
  width: 77.5%;
  padding: 5px;
  margin-left: 1%;
  border-radius: 3px;
}

.relationselect {
  width: 77.5%;
  padding: 5px;
  margin-left: 1%;
  border-radius: 3px;
}

.mobile-undo {
  margin-left: 12px;
}

.email-undo {
  margin-left: 12px;
}

.uimg {
  opacity: 0.8;
  width: 13px;
}

.rlbl {
  padding-left: 5px;
  padding-right: 5px;
}

.glbl {
  margin-right: 69px;
}

.mlbl {
  margin-right: 72px;
  margin-top: 8px;
}

.inltl {
  margin-right: 10px;
  margin-left: 166px;
}

.posit {
  margin-top: 5px;
}

.nposit {
  margin-top: -15px;
}

.inselect {
  width: 260px;
  padding: 5px;
  padding-left: 5px !important;
  margin-left: 10px;
}

.inlbls {
  margin-left: 133px;
  margin-right: 8px;
}

.qlbl {
  margin-left: 133px;
  margin-right: 25px;
}

.olbl {
  margin-right: 39px;
}

.inselects {
  width: 270px;
  padding: 5px;
}

.plds {
  font-size: 20px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

.pimg {
  position: relative;
  width: 20px;
}

.mninp {
  padding: 2.5px;
  padding-left: 7px;
  padding-right: 10px;
  margin-left: 10px;
  width: 244px;
}

.fname_label {
  margin-right: 10px;
  margin-top: 5px;
}

.elbl {
  margin-right: 66px;
  margin-top: 2%;
  margin-left: 15.7%;
}

.einp {
  padding: 5px;
  width: 275px;
}

.pan_pd {
  margin-top: 2px;
}

.mslbl {
  height: 12px;
  margin-left: 5px;
}

.aalbl {
  margin-right: 59px;
  margin-top: 3px;
}

.panlbl {
  margin-top: 3px;
  margin-left: 225px;
  margin-right: 80px;
}

.add-color {
  color: black !important;
}

.no-nom {
  margin-left: -15px;
}

.add-edit {
  margin-left: 8px;
  margin-top: -3px;
}

.w-97 {
  width: 97%;
}

.addinput {
  width: 97%;
  padding: 7px;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  margin-left: -3px;
  font-family: "Inter", sans-serif;
  z-index: 9999;
}

.addinput:hover {
  cursor: pointer;
  border: 1px solid #018231;
}

.addinput:focus {
  border: 1px solid #018231;
}

.sectioncolor {
  background-color: #dddcdc;
  margin-left: -30px;
  padding-right: -10%;
}

.addressbtn {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #6c44fc;
  color: white;
  font-family: "Inter", sans-serif;
}

.edm {
  color: grey;
  font-size: 15px;
  text-align: center;
  margin-top: -10px;
}

.edm:hover {
  cursor: pointer;
}

.itl {
  font-style: italic;
}

.nomlbl {
  margin-right: 20px;
}

Input:hover {
  cursor: pointer;
}

.bcls {
  padding: 10px;
  width: 280px;
  margin-right: 30px;
}

.bankchk {
  float: right;
}

.bankcheck {
  width: 20px;
  height: 30px;
}

.bankchk:checked {
  background-color: #82c46c !important;
}

.addicon {
  width: 35%;
  margin-left: 35%;
  margin-top: 8%;
}

.addbank {
  text-align: center;
  margin-top: 10px;
}

.seglbl {
  margin-right: 20px;
}

.segchk {
  margin-top: 1px;
  width: 102px;
  height: 25px;
}

.segchk1 {
  margin-top: 1px;
  width: 115px;
  height: 25px;
}

.segchk2 {
  margin-top: 1px;
  width: 223px;
  height: 25px;
}

.segchk3 {
  margin-top: 1px;
  width: 25px;
  height: 25px;
}

.seglbls {
  text-align: justify;
  font-size: 15px;
}

.segcheck {
  width: 30px;
  height: 18px;
  margin-top: 2px;
  margin-right: 5px;
}

.segment1 {
  margin-right: 5%;
  width: 50%;
}

.segmentcheck {
  display: block !important;
  margin-left: 29px;
}

.acclbl {
  margin-right: 30px;
}

.segpara {
  text-align: justify;
}

.seghlbl {
  font-size: 1.1rem;
  font-weight: 500;
}

.seghchk {
  border-radius: 3px;
  width: 20px;
  height: 20px;
  margin-top: 3px;
  margin-left: 10px;
}

.seghchk:after {
  left: 7px !important;
  top: 1px;
}

.seginfo {
  color: #018231;
  font-size: 18px !important;
  margin-right: 8px;
}

.nom-edit {
  margin-left: 12px;
}

.nom-del {
  margin-left: 12px;
}

.pimgs {
  position: relative;
  width: 22px;
  color: black;
}

.dimg {
  opacity: 0.6;
  width: 14px;
}

.bpd {
  margin-top: -20px;
}

.vbtn {
  float: right;
}

.nxtbtn {
  width: 18%;
}

.bankname {
  font-weight: 400;
  font-family: "Inter", sans-serif;
  text-transform: uppercase;
  font-size: 1rem;
}

.material-textfield {
  position: relative;
}

.lblz {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  background-color: white;
  color: gray;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.inpzz {
  border-radius: 5px;
  transition: 0.1s ease-out;
}

.inpzz:focus {
  border-color: #018231;
}

.lbltransform {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.lbltransforms {
  top: 0;
  transform: translateY(-50%) scale(0.9);
  z-index: 1;
}

.pinlbl {
  position: absolute;
  font-size: 1rem;
  left: 0;
  top: 50%;
  transform: translateY(-90%);
  background-color: white;
  color: gray;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
}

.inpzz:focus+.pinlbl {
  color: #018231;
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.pinin {
  width: 265px;
}

.pinins {
  width: 265px;
  margin-left: 1px;
}

.acchold {
  width: 49.2%;
}

.rselect {
  width: 260px;
  padding: 5px;
  opacity: 0.7;
}

.radioz {
  border: none !important;
}

.disble {
  width: 48px;
  background-color: transparent;
  border: 1px solid gray;
  border-radius: 3px;
  margin-left: 5px;
  padding: 2px 3px 2px 3px;
}

.aax1,
.aax2 {
  padding: 0px 3px 0px 3px;
  background-color: transparent;
  border: 1px solid gray;
  border-radius: 3px;
  margin-left: 5px;
  margin-bottom: 25px;
  font-size: 19px;
  opacity: 0.7;
}

.aalblz {
  opacity: 0.7;
}

.segchks1 {
  margin-top: 5px;
  margin-right: 13px;
  width: 20px;
  height: 17px;
}

.segchks2 {
  margin-top: 3px;
  opacity: 0.7;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  margin-bottom: -20px;
}

.seghlbl {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 20px;
}

/* Hide the default checkbox */
.select-plan input[type="checkbox"] {
  visibility: hidden;
  top: 0;
}

/* Creating a custom checkbox
  based on demand */
.seghchk {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 1px solid #535353;
  border-radius: 3px;
}

.sub-add .seghchk {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #ffffff;
  border: 1px solid #535353;
}

.seghlbl input:active~.seghchk {
  background-color: gray;
}

.seghlbl input:checked~.seghchk {
  background-color: gray;
}

.seghlbl-active input:checked~.seghchk {
  background-color: #018231 !important;
}

.seghchk:after {
  content: "";
  position: absolute;
  display: none;
}

.seghlbl input:checked~.seghchk:after {
  display: block;
}

.seghlbl .seghchk:after {
  left: 6.5px !important;
  bottom: 10px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.seghchk:after {
  content: "";
  position: absolute;
  display: none;
}

.segmentcheck .sub-add input:disabled~.geekmark {
  background-color: gray !important;
}

.radio_error {
  margin-left: -10px;
  margin-top: -10px;
  position: absolute;
}

.ax {
  margin-top: -8px;
}

.mobile_input {
  margin-top: -5px;
}

.email_input {
  margin-top: -1px;
}

.nom_radio {
  margin-right: 15px;
}

.relation {
  margin-left: -3px;
  padding: 5px;
  border-radius: 5px;
  padding: 6px;
  padding-left: 11px;
  opacity: 0.6;
  width: 97%;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1em;
  opacity: 0.5;
}

.hr-text:before {
  content: "";
  background: #818078;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 1px;
}

.hr-text:after {
  content: attr(data-content);
  position: relative;
  display: inline-block;
  color: black;
  padding: 0 0.5em;
  line-height: 1.5em;
  background-color: #fcfcfa;
}

.sbtn {
  background-color: #04b646;
  padding: 10px 20px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  text-decoration: none;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  float: right;
  margin-right: 1px;
}

.sbtn:hover {
  background-color: #018231;
}

.address_input {
  margin-top: -20px;
}

.address_field {
  width: 51%;
}

.address_fields {
  width: 51%;
  margin-right: -10%;
}

.head4 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: black;
  font-size: 20px;
}

.head5 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: black;
}

.head6 {
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: black;
  font-size: 17px;
}

.color-gray {
  color: gray;
}

.discontinue {
  background-color: gray;
  padding: 10px 20px;
  text-align: center;
  border-radius: 3px;
  color: #fff;
  text-decoration: none;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  float: right;
  margin-top: 25px;
  margin-right: 1px;
}

.nom-undo {
  margin-left: 12px;
}

.pan-input {
  margin-top: -7px;
}

.add-undo {
  width: 24px;
  margin-top: -4px;
  margin-left: 8px;
}

.bank-undo {
  width: 24px;
  margin-left: 12px;
}

.segment-undo {
  margin-left: -2px;
  margin-top: -1px;
}

.update-label {
  color: black;
  font-family: "Inter", sans-serif;
  opacity: 0.8;
}

.btndiv {
  margin-left: 51%;
}

.btndivs {
  margin-left: 75%;
}

.sameasper {
  font-size: 12px;
  margin-bottom: 20px;
  margin-left: 5px;
  margin-top: -4px;
}

.sameaspers {
  font-size: 12px;
  margin-left: 5px;
  margin-top: 10px;
}

.sameasperchk {
  margin-top: -10px;
}

.error-fonts {
  font-size: 16px;
  margin-left: 20px !important;
}

.mark-primary {
  margin-top: -20px;
  margin-bottom: 10px;
}

.uplabel {
  margin-top: -20px;
}

.api-errormsg {
  font-size: 12px !important;
  margin-left: 50%;
  margin-top: -15%;
  position: absolute;
}

.disabled {
  margin-left: -3%;
}

.disable {
  margin-left: -5px;
}

.sameasprimedp {
  margin-top: -30px;
}

.subbtn {
  margin-top: -42%;
}

.bankerror {
  margin-left: 35px;
}

.esign-box {
  text-align: justify;
  padding: 30px 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  min-height: 187px;
}

.addinputs {
  padding: 7px;
  border: 1px solid #c0c0c0;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  z-index: 9999;
  width: 97%;
}

.addinputs:hover {
  cursor: pointer;
  border: 1px solid #018231;
}

.addinputs:focus {
  border: 1px solid #018231;
}

.flexz {
  display: flex;
}

.nom-name {
  margin-left: -15px;
}

.head3 {
  font-size: 19px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  color: black;
}

.sameascheck {
  margin-top: -25px;
}

.btn-files {
  position: relative;
  overflow: hidden;
  height: 200px;
  width: 100%;
}

.btn-files input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
  cursor: pointer;
}

.btn-default.btn-files {
  background-color: #fff;
  border-radius: 7px;
  border: 2px dashed #cccc;
}

.upload-text {
  color: #04b646;
  font-size: 12px;
  position: relative;
  bottom: 40px;
}

.rekyc-footer {
  background: #f8f9fa;
  color: #000;
  width: 100%;
}

.rekyc-footer a {
  color: #0066b3 !important;
}

.rekyc-footer a:hover {
  color: #0066b3;
}

.rekyc-portal {
  margin-top: -50px;
  background-color: #f8f9fa;
  padding: 25px;
}

.rekyc-footer .border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.rekyc-footer p {
  color: #000;
}

.rekyc-footer h2,
.rekyc-footer h3,
.rekyc-footer h4,
.rekyc-footer h5 {
  color: #fff;
}

.rekyc-footer ul li {
  margin-bottom: 10px;
  list-style: none;
}

.rekyc-footer .footer-heading {
  font-size: 20px;
  color: #fff;
  font-weight: 300;
  background-image: url(../img/bg-f.png);
  background-position: left bottom;
  background-repeat: no-repeat;
  padding-bottom: 6px;
}

.straph {
  color: #fff;
  background-color: #04b646;
  font-size: 13px;
  padding: 10px 0;
}

.s-icin {
  display: inline-block;
  color: #fff;
}

.s-icin:hover {
  color: #fff;
}

.tp-6 {
  margin-top: -2px;
  font-size: 13px;
}

.pt-6 {
  margin-top: -2px;
}

#font10 {
  color: #fff;
}

.h-256 {
  height: 256px;
}

.mh-0 {
  height: 0px;
}

.mt-7 {
  margin-top: 41px;
  padding: 11.5px 10px !important;
}

.pos-ab {
  position: absolute !important;
}

.txt-up {
  text-transform: uppercase;
}

.mnerr {
  position: absolute;
  margin-left: 6px;
}

.aadhaarerr {
  margin-left: -120px;
}

.radioerr {
  position: absolute;
  margin-left: 130px;
  margin-top: -10px;
}

.bankadd {
  margin-left: -15px;
}

.tooltip-text {
  width: fit-content !important;
}

.ml-10 {
  margin-left: 103px;
}

.tp-1 {
  font-size: 15.5px;
}

.bankadd {
  margin-left: -15px;
}

.inpt:focus,
.inps:focus,
.sbtn:focus,
.vbtns:focus {
  outline: solid #018231 !important;
  outline-width: 2px !important;
}

.inp-back button:hover {
  color: #04b646 !important;
}

/* For custom radio button */

.radio-color label {
  margin-bottom: 0;
}

.radio-color input[type="radio"] {
  display: none;
}

.radio-color input[type="radio"] {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  margin-right: 10px;
}

.radio-color input[type="radio"]:before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 0px;
  border-radius: 50%;
  margin-right: 5px;
  width: 16px;
  margin-top: 3px;
  height: 16px;
  border: 1px solid #ccc;
  background: #fff;
}

.radio-color input[type="radio"]:checked:before {
  border: 1px solid green;
}

.radio-color input[type="radio"]:disabled:before {
  border: 1px solid gray;
}

.radio-color input[type="radio"]:disabled:after {
  background: gray;
}

.radio-color input[type="radio"]:after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background: green;
  position: absolute;
  border-radius: 50%;
  top: 1px;
  left: 3px;
  opacity: 0;
  transform: scale(0, 0);
}

.radio-color input[type="radio"]:checked:after {
  opacity: 1;
  transform: scale(1, 1);
}

/* For custom checkbox */

.check1 input[type="checkbox"]:before,
.checkbox-color input[type="checkbox"]:before,
.sameasperchk input[type="checkbox"]:before {
  content: "";
  width: 15px;
  height: 15px;
  background: #fff;
  border: 1px solid rgba(75, 101, 132, 0.3);
  border-radius: 3px;
  display: inline-block;
}

.check1 input[type="checkbox"]:before {
  margin-top: -1px;
  margin-left: 1px;
}

.checkbox-color input[type="checkbox"]:before {
  margin-left: -1px;
  margin-top: -1px;
  position: absolute;
}

.sameasperchk input[type="checkbox"]:before {
  margin-left: -1px;
  margin-top: -1px;
  position: absolute;
}

.checkbox-colour input[type="checkbox"]:before {
  content: "";
  background: #fff;
  border: 1px solid rgba(75, 101, 132, 0.3);
  border-radius: 3px;
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 1px;
  margin-top: -1px;
  position: absolute;
  display: none;
}

.primary-checkbox input[type="checkbox"]:before {
  content: "";
  background: #fff;
  border: 1px solid rgba(75, 101, 132, 0.3);
  border-radius: 3px;
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-left: -1px;
  margin-top: 3px;
}

.check1 input[type="checkbox"]:checked:before,
.checkbox-color input[type="checkbox"]:checked:before,
.sameasperchk input[type="checkbox"]:checked:before {
  background: #04b646 no-repeat center;
  background-size: 11px 11px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

.checkbox-colour input[type="checkbox"]:checked:before {
  background: #04b646 no-repeat center;
  background-size: 12px 12px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
  display: block;
}

.primary-checkbox input[type="checkbox"]:checked:before {
  background: #04b646 no-repeat center;
  background-size: 14px 18px;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDQ1LjcwMSA0NS43IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0NS43MDEgNDUuNzsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGQ9Ik0yMC42ODcsMzguMzMyYy0yLjA3MiwyLjA3Mi01LjQzNCwyLjA3Mi03LjUwNSwwTDEuNTU0LDI2LjcwNGMtMi4wNzItMi4wNzEtMi4wNzItNS40MzMsMC03LjUwNCAgICBjMi4wNzEtMi4wNzIsNS40MzMtMi4wNzIsNy41MDUsMGw2LjkyOCw2LjkyN2MwLjUyMywwLjUyMiwxLjM3MiwwLjUyMiwxLjg5NiwwTDM2LjY0Miw3LjM2OGMyLjA3MS0yLjA3Miw1LjQzMy0yLjA3Miw3LjUwNSwwICAgIGMwLjk5NSwwLjk5NSwxLjU1NCwyLjM0NSwxLjU1NCwzLjc1MmMwLDEuNDA3LTAuNTU5LDIuNzU3LTEuNTU0LDMuNzUyTDIwLjY4NywzOC4zMzJ6IiBmaWxsPSIjRkZGRkZGIi8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==);
}

.color-lightgray {
  color: lightgray;
}

.error_code {
  font-size: 8px;
  position: absolute;
  bottom: 5%;
  left: 4%;
}

.other-reason {
  padding: 2.5px;
  padding-left: 7px;
  padding-right: 10px;
  margin-left: 10px;
  width: 700px !important;
}

@media only screen and (max-device-width: 299px) {
  .flex {
    display: block;
  }

  .disabled {
    margin-left: -15px;
  }

  .disable {
    margin-left: -15px;
    text-overflow: ellipsis;
    font-size: 12.5px;
  }

  .pan-label {
    margin-left: 15px;
  }

  .add-color {
    margin-top: 5px;
  }

  .email-edit {
    margin-left: 0px;
    margin-top: -2% !important;
    margin-right: -20% !important;
  }

  .email-undo {
    margin-left: 27px !important;
    margin-top: -2% !important;
    margin-right: -25% !important;
  }

  .box-sections {
    width: 80vw;
    height: 90vh;
  }

  .aad {
    margin-left: -50px;
    margin-top: 50px;
  }

  .pan-input {
    margin-left: -90px;
  }

  .inselect {
    margin-left: -5px;
  }

  .inp-back {
    margin-left: -20px;
  }

  .box-section-add {
    width: 80vw;
    height: auto;
    overflow-y: scroll;
  }

  .sbtn {
    margin-top: -15px;
  }

  .lblz {
    font-size: 13px;
  }

  .flatlbl {
    width: 158px !important;
  }

  .addressbtn {
    font-size: 13px;
  }

  .sameascheck {
    margin-top: -40px;
  }

  .segflex {
    width: 300px;
  }

  .rupees {
    margin-top: -50px !important;
  }

  .addsbtn {
    margin-top: -10px;
  }

  .ipv-pd {
    margin-left: -70px !important;
  }

  .box-sections {
    width: 80vw;
    height: auto;
  }

  .btndiv {
    margin-top: -18%;
    margin-left: 20px !important;
  }

  .edit-bt {
    margin-top: -50px;
    right: 10% !important;
  }

  .otp-timer {
    right: 10% !important;
    font-size: 11px;
  }

  .box-sectionx {
    width: 80vw !important;
    height: auto;
  }

  .segment_mobile {
    margin-top: -40px;
  }

  .check2 {
    margin-top: -155px;
  }

  .rad_col {
    margin-right: 100px;
  }

  .acumen-logo {
    width: 50%;
  }

  .nominee-box {
    margin-left: -30px;
    width: 85vw;
  }

  .segchks2 {
    font-size: 12px !important;
    margin-top: 6px;
    margin-left: -8px;
  }

  .address_fields {
    margin-top: 5px;
  }

  .lblcheck {
    text-align: left;
    font-size: 12px;
  }

  .ytlink {
    font-size: 13px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    margin-right: -50px;
  }

  .status-pd {
    margin-left: -50px !important;
  }

  .thankpg {
    font-size: 20px;
    font-weight: 600;
  }

  .inp-back {
    margin-left: -6px;
  }

  .uploadbtn {
    margin-left: -24px !important;
  }

  .upbtn {
    padding: 10px 40px !important;
  }

  .text_left {
    text-align: left !important;
  }

  .flexz {
    display: block;
  }

  .aad {
    margin-top: -5px;
  }

  .pan-input {
    margin-left: 0px;
  }

  .segflex {
    width: 500px !important;
    margin-left: -20px;
    margin-right: -10px;
  }

  .rupees {
    font-size: 20px !important;
    margin-left: 20px !important;
    margin-right: 5px;
  }

  .seghlbl {
    margin-left: -20px;
    font-size: 18px;
    margin-right: -20px;
  }

  .segment_mobile {
    margin-top: 10px !important;
    margin-left: -20px;
  }

  .nom-del {
    margin-left: 5px;
    margin-right: -20px;
  }

  .inps {
    margin-right: -25px;
  }

  .btndiv {
    margin-left: -20px !important;
  }

  .inps {
    margin-left: -10px;
  }

  .check2 {
    margin-top: -225px !important;
  }

  .upload-text {
    color: #04b646;
    font-size: 10px;
    position: relative;
    bottom: 10px;
  }

  .mt-7 {
    margin-top: 0px;
  }

  .h-256 {
    height: auto;
  }

  .upload-message {
    margin-top: 10px;
  }

  .btn-files {
    height: 150px;
  }

  .h-120 {
    margin-bottom: 20px !important;
  }

  .subbtn {
    margin-top: -30px;
  }

  .mninp {
    width: 100%;
    margin-left: -5px;
  }

  .vbtns {
    margin-top: -15px;
  }

  .error-font {
    position: absolute;
    margin-left: -80px;
    margin-right: -50px;
    margin-top: -10px;
  }

  .api-errormsg {
    margin-left: 18%;
    margin-top: -9%;
  }

  .check1 input[type="checkbox"]:before {
    margin-top: -1px;
    margin-left: -1px;
  }
}

@media only screen and (min-device-width: 300px) and (max-device-width: 329px) {
  .flex {
    display: block;
  }

  .disabled {
    margin-left: -15px;
  }

  .disable {
    margin-left: -15px;
    text-overflow: ellipsis;
    font-size: 12.5px;
  }

  .pan-label {
    margin-left: 15px;
  }

  .add-color {
    margin-top: 5px;
  }

  .email-edit {
    margin-left: 0px;
    margin-top: -2% !important;
    margin-right: -20% !important;
  }

  .email-undo {
    margin-left: 27px !important;
    margin-top: -2% !important;
    margin-right: -25% !important;
  }

  .box-sections {
    width: 80vw;
    height: 90vh;
  }

  .cancelbtn {
    margin-left: -2px;
  }

  .inps {
    margin-left: 28px;
  }

  .aad {
    margin-left: -50px;
  }

  .pan-input {
    margin-left: -90px;
  }

  .inselect {
    margin-left: -5px;
  }

  .inp-back {
    margin-left: -20px;
  }

  .box-section-add {
    width: 80vw;
    height: auto;
    overflow-y: scroll;
  }

  .sbtn {
    margin-top: -15px;
  }

  .lblz {
    font-size: 13px;
  }

  .flatlbl {
    width: 158px !important;
  }

  .addressbtn {
    font-size: 13px;
  }

  .segflex {
    width: 300px;
  }

  .rupees {
    margin-top: -50px !important;
  }

  .addsbtn {
    margin-top: -10px;
  }

  .ipv-pd {
    margin-left: -70px !important;
  }

  .box-sections {
    width: 80vw;
    height: auto;
  }

  .btndiv {
    margin-top: -18%;
    margin-left: 20px !important;
  }

  .edit-bt {
    margin-top: -50px;
    right: 10% !important;
  }

  .otp-timer {
    right: 10% !important;
    font-size: 11px;
  }

  .box-sectionx {
    width: 80vw !important;
    height: auto;
  }

  .check2 {
    margin-top: -155px;
  }

  .rad_col {
    margin-right: 100px;
  }

  .acumen-logo {
    width: 50%;
  }

  .nominee-box {
    margin-left: -30px;
  }

  .segchks2 {
    font-size: 12px !important;
    margin-top: 6px;
    margin-left: -8px;
  }

  .address_fields {
    margin-top: 5px;
  }

  .lblcheck {
    text-align: left;
    font-size: 12px;
  }

  .ytlink {
    font-size: 13px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    margin-right: -50px;
  }

  .status-pd {
    margin-left: -50px !important;
  }

  .thankpg {
    font-size: 20px;
    font-weight: 600;
  }

  .inp-back {
    margin-left: -6px;
  }

  .uploadbtn {
    margin-left: -24px !important;
  }

  .upbtn {
    padding: 10px 40px !important;
  }

  .text_left {
    text-align: left !important;
  }

  .flexz {
    display: block;
  }

  .aad {
    margin-top: -5px;
  }

  .pan-input {
    margin-left: 0px;
  }

  .segflex {
    width: 500px !important;
    margin-left: -20px;
    margin-right: -10px;
  }

  .rupees {
    font-size: 20px !important;
    margin-left: 20px !important;
    margin-right: 5px;
  }

  .seghlbl {
    margin-left: -20px;
    font-size: 18px;
    margin-right: -20px;
  }

  .segment_mobile {
    margin-top: 10px !important;
    margin-left: -20px;
  }

  .nom-del {
    margin-left: 5px;
    margin-right: -20px;
  }

  .inps {
    margin-right: -25px;
  }

  .cancelbtn {
    margin-left: -22px;
  }

  .inps {
    margin-left: 0px;
  }

  .check2 {
    margin-top: -190px;
  }

  .upload-text {
    color: #04b646;
    font-size: 12px;
    position: relative;
    bottom: 20px;
  }

  .mt-7 {
    margin-top: 0px;
  }

  .h-256 {
    height: auto;
  }

  .h-120 {
    margin-bottom: 20px !important;
  }

  .subbtn {
    margin-top: -30px;
  }

  .mninp {
    width: 100%;
    margin-left: -5px;
  }

  .mslbl {
    margin-left: 0px;
  }

  .api-errormsg {
    margin-left: 18%;
    margin-top: -9%;
  }

  .check1 input[type="checkbox"]:before {
    margin-top: -1px;
    margin-left: -1px;
  }
}

@media only screen and (min-device-width: 330px) and (max-device-width: 360px) {
  .flex {
    display: block;
  }

  .disabled {
    margin-left: -15px;
  }

  .disable {
    margin-left: -15px;
    font-size: 15px;
  }

  .pan-label {
    margin-left: 15px;
  }

  .add-color {
    margin-top: 5px;
  }

  .email-edit {
    position: relative;
  }

  .email-undo {
    position: relative;
  }

  .box-sections {
    width: 80vw;
    height: auto;
  }

  .aad {
    margin-left: -100px;
  }

  .pan-input {
    margin-left: -90px;
  }

  .inselect {
    margin-left: -5px;
  }

  .inp-back {
    margin-left: -20px;
  }

  .box-section-add {
    width: 80vw;
    height: auto;
    overflow-y: scroll;
  }

  .sbtn {
    margin-top: -15px;
  }

  .lblz {
    font-size: 13px;
  }

  .flatlbl {
    width: 158px !important;
  }

  .addressbtn {
    font-size: 13px;
  }

  .segflex {
    width: 300px;
  }

  .rupees {
    margin-top: -50px !important;
  }

  .addsbtn {
    margin-top: -10px;
  }

  .ipv-pd {
    margin-left: -70px !important;
  }

  .box-sections {
    width: 80vw;
    height: auto;
  }

  .btndiv {
    margin-top: -17%;
    margin-left: 20px !important;
  }

  .cancelbtn {
    margin-left: -2px;
  }

  .inps {
    margin-left: 28px;
  }

  .edit-bt {
    margin-top: -50px;
    right: 10% !important;
  }

  .otp-timer {
    right: 10% !important;
    font-size: 11px;
  }

  .box-sectionx {
    width: 90vw !important;
    height: auto;
  }

  .box-sectionz {
    height: 80vh;
  }

  .segment_mobile {
    margin-top: -10px !important;
  }

  .check2 {
    margin-top: -155px;
  }

  .rad_col {
    margin-right: 100px;
  }

  .acumen-logo {
    width: 50%;
  }

  .segchks2 {
    font-size: 12px !important;
    margin-top: 6px;
    margin-left: -8px;
  }

  .address_fields {
    margin-top: 5px;
  }

  .lblcheck {
    text-align: left;
    font-size: 12px;
  }

  .ytlink {
    font-size: 13px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    margin-right: -50px;
  }

  .status-pd {
    margin-left: -50px !important;
  }

  .thankpg {
    font-size: 20px;
    font-weight: 600;
  }

  .inp-back {
    margin-left: -6px;
  }

  .uploadbtn {
    margin-left: -24px !important;
  }

  .upbtn {
    padding: 10px 40px !important;
  }

  .text_left {
    text-align: left !important;
  }

  .btndiv {
    margin-left: 0% !important;
  }

  .inps {
    margin-left: 15px;
  }

  .mt-7 {
    margin-top: 0px;
  }

  .h-256 {
    height: auto;
  }

  .upload-text {
    margin-top: 10px;
  }

  .h-120 {
    margin-bottom: 20px !important;
  }

  .subbtn {
    margin-top: -30px;
  }

  .mninp {
    width: 100%;
    margin-left: -5px;
  }

  .mslbl {
    margin-left: 0px;
  }

  .error-font {
    margin-left: -145px;
    margin-top: 5px;
  }

  .api-errormsg {
    margin-left: 18%;
    margin-top: -9%;
  }

  .check1 input[type="checkbox"]:before {
    margin-top: -1px;
    margin-left: -1px;
  }
}

@media only screen and (min-device-width: 361px) and (max-device-width: 379px) {
  .flex {
    display: block;
  }

  .disabled {
    margin-left: -15px;
  }

  .disable {
    margin-left: -15px;
    text-overflow: ellipsis;
    font-size: 15px;
  }

  .pan-label {
    margin-left: 15px;
  }

  .add-color {
    margin-top: 5px;
  }

  .email-edit {
    position: relative;
  }

  .email-undo {
    position: relative;
  }

  .aad {
    margin-left: -50px;
  }

  .pan-input {
    margin-left: -90px;
  }

  .inselect {
    margin-left: -5px;
  }

  .inp-back {
    margin-left: -20px;
  }

  .box-section-add {
    width: 80vw;
    height: auto;
    overflow-y: scroll;
  }

  .sbtn {
    margin-top: -15px;
  }

  .lblz {
    font-size: 13px;
  }

  .flatlbl {
    width: 158px !important;
  }

  .addressbtn {
    font-size: 13px;
  }

  .segflex {
    width: 300px;
  }

  .rupees {
    margin-top: -50px !important;
  }

  .addsbtn {
    margin-top: -10px;
  }

  .ipv-pd {
    margin-left: -70px !important;
  }

  .box-sections {
    width: 80vw;
    height: auto;
  }

  .btndiv {
    margin-top: -17%;
    margin-left: 20px !important;
  }

  .cancelbtn {
    margin-left: -2px;
  }

  .inps {
    margin-left: 28px;
  }

  .edit-bt {
    margin-top: -50px;
    right: 10% !important;
  }

  .otp-timer {
    right: 10% !important;
    font-size: 11px;
  }

  .box-sectionx {
    width: 85vw !important;
    height: auto;
  }

  .segment_mobile {
    margin-top: -10px;
  }

  .check2 {
    margin-top: -155px;
  }

  .rad_col {
    margin-right: 100px;
  }

  .acumen-logo {
    width: 50%;
  }

  .segchks2 {
    font-size: 12px !important;
    margin-top: 6px;
    margin-left: -8px;
  }

  .address_fields {
    margin-top: 5px;
  }

  .lblcheck {
    text-align: left;
    font-size: 12px;
  }

  .ytlink {
    font-size: 13px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    margin-right: -50px;
  }

  .status-pd {
    margin-left: -50px !important;
  }

  .thankpg {
    font-size: 20px;
    font-weight: 600;
  }

  .inp-back {
    margin-left: -6px;
  }

  .uploadbtn {
    margin-left: -24px !important;
  }

  .upbtn {
    padding: 10px 40px !important;
  }

  .text_left {
    text-align: left !important;
  }

  .btndiv {
    margin-left: 0% !important;
  }

  .inps {
    margin-left: 15px;
  }

  .box-sectionz {
    height: 80vh;
  }

  .mt-7 {
    margin-top: 0px;
  }

  .h-120 {
    margin-bottom: 20px !important;
  }

  .subbtn {
    margin-top: -25px;
  }

  .mninp {
    width: 100%;
    margin-left: -5px;
  }

  .mslbl {
    margin-left: 0px;
  }

  .error-font {
    margin-left: -145px;
    margin-top: 25px;
  }

  .api-errormsg {
    margin-left: 18%;
    margin-top: -9%;
  }

  .check1 input[type="checkbox"]:before {
    margin-top: -1px;
    margin-left: -1px;
  }
}

@media only screen and (min-device-width: 380px) and (max-device-width: 400px) {
  .flex {
    display: block;
  }

  .disabled {
    margin-left: -15px;
  }

  .disable {
    margin-left: -15px;
    text-overflow: ellipsis;
    font-size: 15px;
  }

  .pan-label {
    margin-left: 15px;
  }

  .add-color {
    margin-top: 5px;
  }

  .email-edit {
    position: relative;
  }

  .email-undo {
    position: relative;
  }

  .box-sectionz {
    height: 80vh;
  }

  .aad {
    margin-left: -50px;
  }

  .pan-input {
    margin-left: -90px;
  }

  .inselect {
    margin-left: -5px;
  }

  .inp-back {
    margin-left: -20px;
  }

  .box-section-add {
    width: 80vw;
    height: auto;
    overflow-y: scroll;
  }

  .sbtn {
    margin-top: -15px;
  }

  .lblz {
    font-size: 13px;
  }

  .flatlbl {
    width: 158px !important;
  }

  .addressbtn {
    font-size: 13px;
  }

  .segflex {
    width: 300px;
  }

  .rupees {
    margin-top: -50px !important;
  }

  .addsbtn {
    margin-top: -10px;
  }

  .ipv-pd {
    margin-left: -70px !important;
  }

  .box-sections {
    width: 80vw;
    height: auto;
  }

  .btndiv {
    margin-top: -20%;
    margin-left: 20px !important;
  }

  .edit-bt {
    margin-top: -50px;
    right: 10% !important;
  }

  .otp-timer {
    right: 10% !important;
    font-size: 11px;
  }

  .box-sectionx {
    width: 80vw !important;
    height: auto;
  }

  .segment_mobile {
    margin-top: -10px;
  }

  .check2 {
    margin-top: -135px;
  }

  .rad_col {
    margin-right: 100px;
  }

  .acumen-logo {
    width: 50%;
  }

  .segchks2 {
    font-size: 12px !important;
    margin-top: 6px;
    margin-left: -8px;
  }

  .address_fields {
    margin-top: 5px;
  }

  .lblcheck {
    text-align: left;
    font-size: 12px;
  }

  .ytlink {
    font-size: 13px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    margin-right: -50px;
  }

  .status-pd {
    margin-left: -50px !important;
  }

  .thankpg {
    font-size: 20px;
    font-weight: 600;
  }

  .inp-back {
    margin-left: -6px;
  }

  .uploadbtn {
    margin-left: -24px !important;
  }

  .upbtn {
    padding: 10px 40px !important;
  }

  .text_left {
    text-align: left !important;
  }

  .box-sectionz {
    height: 75vh;
  }

  .mt-7 {
    margin-top: 0px;
  }

  .h-120 {
    margin-bottom: 20px !important;
  }

  .subbtn {
    margin-top: -25px;
  }

  .mninp {
    width: 100%;
    margin-left: -5px;
  }

  .mslbl {
    margin-left: 0px;
  }

  .error-font {
    margin-left: -145px;
    margin-top: 5px;
  }

  .api-errormsg {
    margin-left: 18%;
    margin-top: -9%;
  }

  .check1 input[type="checkbox"]:before {
    margin-top: -1px;
    margin-left: -1px;
  }
}

@media screen and (min-width: 401px) and (max-width: 500px) {
  .flex {
    display: block;
  }

  .disabled {
    margin-left: -15px;
  }

  .disable {
    margin-left: -15px;
    text-overflow: ellipsis;
  }

  .email-edit {
    position: relative;
  }

  .email-undo {
    position: relative;
  }

  .pan-label {
    margin-left: 15px;
  }

  .add-color {
    margin-top: 5px;
  }

  .acumen-logo {
    width: 50%;
  }

  .box-sections {
    width: 80vw;
    height: auto;
  }

  .box-section-add {
    width: 90vw;
    height: auto;
    overflow-y: scroll;
  }

  .box-sectionz {
    height: 75vh;
  }

  .aad {
    margin-left: -50px;
  }

  .pan-input {
    margin-left: -70px;
  }

  .inselect {
    margin-left: -5px;
  }

  .sbtn {
    margin-top: -15px;
  }

  .lblz {
    font-size: 14px;
  }

  .flatlbl {
    width: 168px !important;
  }

  .segflex {
    width: 300px;
  }

  .rupees {
    margin-top: -50px !important;
  }

  .addsbtn {
    margin-top: -10px;
  }

  .ipv-pd {
    margin-left: -70px !important;
  }

  .btndiv {
    margin-top: -20%;
    margin-left: 50px !important;
  }

  .edit-bt {
    margin-top: -50px;
    right: 10% !important;
  }

  .otp-timer {
    right: 10% !important;
  }

  .box-sectionx {
    width: 80vw !important;
    height: auto;
  }

  .segment_mobile {
    margin-top: -10px;
  }

  .rad_col {
    margin-right: 100px;
  }

  .address_field {
    margin-top: 5px;
  }

  .address_fields {
    margin-top: 5px;
  }

  .lblcheck {
    text-align: left;
    font-size: 12px;
  }

  .check2 {
    margin-top: -135px;
  }

  .ytlink {
    font-size: 13px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    margin-right: -50px;
  }

  .status-pd {
    margin-left: -50px !important;
  }

  .thankpg {
    font-size: 20px;
    font-weight: 600;
  }

  .text_left {
    text-align: left !important;
  }

  .ipvbtn {
    margin-left: 18px !important;
  }

  .mt-7 {
    margin-top: 0px;
  }

  .upload-message {
    margin-top: -10px;
  }

  .h-120 {
    margin-bottom: 20px !important;
  }

  .subbtn {
    margin-top: -25px;
  }

  .mninp {
    width: 100%;
    margin-left: -5px;
  }

  .mslbl {
    margin-left: 0px;
  }

  .error-font {
    margin-left: -150px;
    margin-top: 10px;
  }

  .nominee-box {
    width: 75vw;
  }

  .api-errormsg {
    margin-left: 18%;
    margin-top: -9%;
  }

  .check1 input[type="checkbox"]:before {
    margin-top: -1px;
    margin-left: -1px;
  }
}

@media screen and (min-width: 501px) and (max-width: 599px) {
  .ipv-pd {
    margin-left: -50px !important;
  }

  .h-120 {
    margin-bottom: 20px !important;
    height: auto;
    width: 200px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .mninp {
    width: 209%;
    margin-left: -110px;
  }

  .error-font {
    margin-left: -100px;
    margin-top: -30px;
  }

  .other-reason {
    width: 100% !important;
  }
}

@media screen and (min-width: 600px) and (max-width: 799px) {
  .segflex {
    width: 270px;
  }

  .mobile-edit {
    position: absolute;
    margin-left: 102px;
    margin-right: -20px;
  }

  .mobile-undo {
    position: absolute;
    margin-left: 125px;
    margin-right: -20px;
  }

  .email-edit {
    margin-left: 15px;
    margin-right: -20px;
  }

  .email-undo {
    margin-left: 30px;
    margin-right: -20px;
  }

  .inselect {
    margin-left: 50px !important;
  }

  .disabled {
    margin-left: 18px;
  }

  .disable {
    margin-left: 20px !important;
  }

  .mt-sm-24 {
    margin-top: -24px;
  }

  .mslbl {
    margin-left: 0px;
  }

  .rlbl {
    font-size: 12.5px;
  }

  .aad {
    margin-left: -10px;
  }

  .addsbtn {
    margin-top: -20px;
  }

  .upload-text {
    color: #04b646;
    font-size: 12px;
    position: relative;
    bottom: 20px;
  }

  .box-sectionz {
    height: 70vh;
  }

  .btn-files {
    height: 150px;
  }

  .upload-text {
    color: #04b646;
    font-size: 9px;
    position: relative;
    bottom: 10px;
    margin-left: -5px;
  }

  .info-btn i {
    margin-left: -1px;
  }

  .mt-7 {
    margin-top: 96px;
    font-size: 13px !important;
    padding: 13px 10px !important;
  }

  .h-256 {
    height: auto;
  }

  .text_left {
    text-align: left;
  }

  .h-120 {
    height: 100px;
  }

  .ipv-pd {
    margin-left: -50px !important;
  }

  .w-414 {
    width: 300px;
  }

  .lp-10 {
    margin-left: -10px;
  }

  .font-12 {
    font-size: 11px;
  }

  .text_left {
    text-align: left !important;
  }

  .nom-namelbl {
    width: 90px;
    font-size: 11px;
    margin-left: 0px;
  }

  .nom-lbl {
    width: 140px;
    font-size: 11px;
    margin-left: 0px;
  }

  .nom-lbls {
    width: 110px;
    font-size: 11px;
    margin-left: 0px;
  }

  .other-reason {
    width: 100% !important;
  }

  .closure-reasons {
    max-width: 100% !important;
  }

  .m-65 {
    margin-left: 58vw;
  }
}

@media only screen and (min-width: 760px) and (max-width: 769px) {
  .font10 {
    font-size: 10px !important;
  }

  #font10 {
    font-size: 10px !important;
  }

  .pt-6 {
    margin-top: 0px;
  }

  .tp-6 {
    margin-top: -5px;
    font-size: 10px;
  }

  .upload-message {
    margin-top: 20px;
  }

  .mninp {
    width: 68%;
    margin-left: 50px;
  }

  .other-reason {
    width: 100% !important;
  }

  .closure-reasons {
    max-width: 100% !important;
  }
}

@media only screen and (min-width: 770px) and (max-width: 929px) {
  .font10 {
    font-size: 11px !important;
  }

  #font10 {
    font-size: 11px !important;
  }

  .pt-6 {
    margin-top: 0px;
  }

  .tp-6 {
    margin-top: -2.5px;
    font-size: 10px;
  }

  .other-reason {
    width: 100% !important;
  }

  .closure-reasons {
    max-width: 100% !important;
  }

  .m-65 {
    margin-left: 60vw;
  }
}

@media screen and (min-width: 800px) and (max-width: 999px) {
  .segflex {
    width: 290px;
  }

  .mobile-edit {
    position: absolute;
    margin-left: 102px;
    margin-right: -20px;
  }

  .mobile-undo {
    position: absolute;
    margin-left: 125px;
    margin-right: -20px;
  }

  .email-edit {
    margin-left: 15px;
    margin-right: -20px;
  }

  .email-undo {
    margin-left: 30px;
    margin-right: -20px;
  }

  .inselect {
    margin-left: 30px !important;
  }

  .disabled {
    margin-left: 18px;
  }

  .disable {
    margin-left: 20px !important;
  }

  .mt-sm-24 {
    margin-top: -24px;
  }

  .mslbl {
    margin-left: 0px;
  }

  .rlbl {
    font-size: 14.5px;
  }

  .nom-lbl {
    width: 165px;
    font-size: 13px;
    margin-left: -3px;
  }

  .nom-lbls {
    width: 130px;
    font-size: 13px;
  }

  .btn-files {
    height: 160px;
  }

  .upload-text {
    color: #04b646;
    font-size: 9.5px;
    position: relative;
    bottom: 15px;
  }

  .upload-message {
    margin-top: 10px;
  }

  .box-sectionz {
    height: 70vh;
  }

  .mt-7 {
    margin-top: 68px;
    font-size: 15px !important;
    padding: 13px 10px !important;
  }

  .h-256 {
    height: auto;
  }

  .text_left {
    text-align: left;
  }

  .h-120 {
    height: 100px;
  }

  .ipv-pd {
    margin-left: -35px !important;
  }

  .w-414 {
    width: 300px;
  }

  .lp-10 {
    margin-left: -10px;
  }

  .font-12 {
    font-size: 12px;
  }

  .mninp {
    width: 67%;
    margin-left: 30px;
  }

  .other-reason {
    width: 100% !important;
  }

  .closure-reasons {
    max-width: 100% !important;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
  .segflex {
    width: 350px;
  }

  .lblz {
    font-size: 14px;
  }

  .mninp {
    width: 69%;
  }

  .upload-text {
    color: #04b646;
    font-size: 12px;
    position: relative;
    bottom: 20px;
  }

  .mt-7 {
    margin-top: 68px;
  }

  .h-256 {
    height: 306px;
  }

  .h-120 {
    height: 100px;
  }

  .m-65 {
    margin-left: 65vw;
  }
}

@media screen and (max-width: 1100px) {
  .straph {
    padding-left: 10px !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .mninp {
    width: 228px !important;
  }
}

@media screen and (min-width: 1100px) and (max-width: 1399px) {
  .m-65 {
    margin-left: 65vw;
  }
}

@media screen and (min-width: 1400px) {
  .box-sectionx {
    width: 40vw;
  }

  .box-sectionz {
    width: 70vw;
    height: 90vh;
  }

  .box-section-add {
    width: 40vw;
    height: 90vh;
  }

  .m-65 {
    margin-left: 68vw;
  }
}

@media screen and (min-height: 100px) and (max-height: 500px) {
  .box-sectionz {
    height: 80vh;
    overflow-y: scroll;
  }

  .box-section-add {
    height: 80vh;
    overflow-y: scroll;
  }

  .box-sectionx {
    height: 80vh;
    overflow-y: scroll;
  }

  .flatlbl {
    width: 190px;
  }
}